import React from "react"
import { Grid, Box } from "@material-ui/core"
import { chunk } from "lodash"

const FeedbackTab = ({ feedback }) => {
  return (
    <div className="feedback-container">
      <div className="quote top" />
      <div className="feedback-wrapper">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            {chunk(feedback, 2)[0].map((feedback, index) => (
              <Box
                sx={{ boxShadow: 3 }}
                className={
                  index % 2 === 0 ? `odd feedback-item` : `even feedback-item`
                }
                key={index}
              >
                {feedback}
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} md={6}>
            {chunk(feedback, 2).length > 1 &&
              chunk(feedback, 2)[1].map((feedback, index) => (
                <Box
                  sx={{ boxShadow: 3 }}
                  className={
                    index % 2 === 0 ? `even feedback-item` : `odd feedback-item`
                  }
                  key={index}
                >
                  {feedback}
                </Box>
              ))}
          </Grid>
        </Grid>
      </div>
      <div className="quote bottom" />
    </div>
  )
}

export default FeedbackTab
