import React, { useEffect, useState } from "react"
import { Button, Chip, TextField } from "@material-ui/core"
import Downshift from "downshift"
import { makeStyles } from "@material-ui/core/styles"
import { addExternalTags } from "../../httpRequests/report"
const useStyles = makeStyles(theme => ({
  input: {
    border: "1px solid #F0F1F7",
    background: "#FCFDFE",
    minWidth: 624,
    fontFamily: "LatoRegular",
    fontSize: 18,
    paddingLeft: "20px",
    "@media screen and (max-width: 767px)": {
      minWidth: "100%",
    },
  },
}))

const ModalEditTags = ({
  setOpen,
  tags,
  setLoading,
  reportId,
  reportType,
  setSnackbarOpen,
  setSuccess,
  setMessage,
  setErrorMessage,
  setErrorOpen,
  refetch,
  setRefetch,
}) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = useState("")
  const [selectedItem, setSelectedItem] = useState([])
  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    setSelectedItem(tags)
  }, [tags])

  const handleChange = item => {
    let newSelectedItem = [...selectedItem]
    if (newSelectedItem.indexOf(item) === -1) {
      newSelectedItem = [...newSelectedItem, item]
    }
    setInputValue("")
    setSelectedItem(newSelectedItem)
  }

  const handleDelete = item => () => {
    setDisabled(false)
    const newSelectedItem = [...selectedItem]
    newSelectedItem.splice(newSelectedItem.indexOf(item), 1)
    setSelectedItem(newSelectedItem)
  }

  const handleInputChange = event => {
    setInputValue(event.target.value)
  }

  const handleAddTags = async () => {
    setLoading(true)
    setOpen(false)

    try {
      const response = await addExternalTags({
        id: parseInt(reportId),
        type: reportType,
        tags: selectedItem,
      })
      if (response.data.statusCode === 200) {
        setSnackbarOpen(true)
        setSuccess("success")
        setMessage("Tag(s)'s been added successfully!")
        setRefetch(!refetch)
      } else {
        setSnackbarOpen(true)
        setSuccess("error")
        setMessage("Oops! Please try again.")
      }
    } catch (error) {
      if (error.response.data.devMessage.includes("Method Not")) {
        setErrorOpen(true)
        setSuccess("error")
        setErrorMessage("You don't have a permission to perform this action.")
      }
    }

    setLoading(false)
  }

  return (
    <div className="edit-tags">
      <h3>
        <svg
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.4377 4.97721C14.0104 4.97721 12.8528 6.13493 12.8528 7.56217C12.8528 8.98951 14.0105 10.1471 15.4377 10.1471C16.8651 10.1471 18.0227 8.98941 18.0227 7.56217C18.0227 6.13483 16.865 4.97721 15.4377 4.97721ZM15.4377 8.98519C14.6702 8.98519 14.0499 8.36489 14.0499 7.59734C14.0499 6.82978 14.6702 6.20949 15.4377 6.20949C16.2053 6.20949 16.8256 6.82978 16.8256 7.59734C16.8256 8.36498 16.2061 8.98519 15.4377 8.98519Z"
            fill="#252733"
            stroke="#252733"
            stroke-width="0.5"
          />
          <path
            d="M18.4738 2.08497L18.4738 2.08471L18.4621 2.08509L13.0598 2.25939L13.0598 2.25906L13.047 2.26013C12.1533 2.33501 11.3312 2.70734 10.6953 3.34322L2.92478 11.1138C1.81642 12.2221 1.81641 14.0184 2.92478 15.1266C2.92478 15.1266 2.92479 15.1266 2.92479 15.1266L7.87346 20.0753C8.40643 20.6083 9.13147 20.9149 9.86238 20.9149C10.5843 20.9149 11.3141 20.6484 11.8542 20.0724L19.6228 12.3038C20.2586 11.668 20.6313 10.8444 20.7059 9.91719L20.7061 9.91721L20.7065 9.90683L20.9149 4.54203C20.9538 3.87045 20.6756 3.24611 20.2148 2.7853C19.7466 2.31705 19.1243 2.0858 18.4738 2.08497ZM19.6836 4.49244L19.5446 9.85222C19.5123 10.4444 19.23 11.0137 18.8162 11.4275L18.993 11.6042L18.8162 11.4275L11.0098 19.2339C10.3752 19.8685 9.34937 19.8685 8.71467 19.2339L3.766 14.2852C3.13142 13.6506 3.1314 12.6248 3.766 11.9901C3.76601 11.9901 3.76601 11.9901 3.76601 11.9901L11.5375 4.21861C11.9516 3.80447 12.5216 3.52203 13.1143 3.49017C13.1148 3.49014 13.1154 3.49011 13.116 3.49008L18.4747 3.28199C18.8283 3.28301 19.1105 3.39889 19.3391 3.62654C19.5647 3.85219 19.6828 4.16838 19.6836 4.49244Z"
            fill="#252733"
            stroke="#252733"
            stroke-width="0.5"
          />
        </svg>
        Add Tags
      </h3>

      <div className="tag-input-wrapper">
        <Downshift
          id="downshift-multiple"
          inputValue={inputValue}
          onChange={handleChange}
          selectedItem={selectedItem}
        >
          {({ getInputProps }) => {
            const { onChange } = getInputProps()
            return (
              <div>
                <TextField
                  className={classes.input}
                  fullWidth
                  value={inputValue}
                  placeholder="Enter tags"
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      const newSelectedItem = [...selectedItem]
                      const duplicatedValues = newSelectedItem.indexOf(
                        event.target.value.trim()
                      )

                      if (duplicatedValues !== -1) {
                        setInputValue("")
                        return
                      }
                      if (!event.target.value.replace(/\s/g, "").length) return

                      newSelectedItem.push(event.target.value.trim())
                      setSelectedItem(newSelectedItem)
                      setInputValue("")
                      setDisabled(false)
                    }
                    if (
                      selectedItem.length &&
                      !inputValue.length &&
                      event.key === "Backspace"
                    ) {
                      setSelectedItem(
                        selectedItem.slice(0, selectedItem.length - 1)
                      )
                    }
                  }}
                  InputProps={{
                    onChange: event => {
                      handleInputChange(event)
                      onChange(event)
                    },
                  }}
                />
              </div>
            )
          }}
        </Downshift>
        <div className="legend">
          <p>
            Hit enter to create tag{" "}
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.93767 7.9245L4.93767 7.92443L4.93151 7.92475C4.79485 7.93188 4.66461 7.98501 4.5616 8.07532L0.963244 11.1339L0.963242 11.1339L0.96232 11.1347C0.827309 11.2506 0.75 11.4198 0.75 11.5972C0.75 11.7746 0.82731 11.9438 0.96232 12.0597L0.963244 12.0605L4.55334 15.1121C4.67126 15.2326 4.83379 15.2994 5.00307 15.296H5.0031C5.17747 15.2924 5.34273 15.2138 5.45581 15.0805C5.5691 14.9469 5.61932 14.7706 5.59333 14.5975C5.56889 14.4295 5.47567 14.2805 5.33762 14.1848L3.00999 12.21H14.6758C15.0023 12.21 15.2858 11.9265 15.2858 11.6V1.3422C15.2887 1.15758 15.2006 0.995943 15.0829 0.887996C14.9644 0.77943 14.7943 0.705362 14.6089 0.72589L14.3299 0.756787L14.3452 0.823406C14.1756 0.93682 14.0627 1.13264 14.0658 1.34214V10.99H3.00881L5.34703 9.00163C5.3473 9.0014 5.34757 9.00117 5.34785 9.00094C5.546 8.83423 5.61756 8.55971 5.52482 8.31747C5.4326 8.07405 5.19645 7.91739 4.93767 7.9245Z"
                fill="#9FA2B4"
                stroke="#9FA2B4"
                stroke-width="0.5"
              />
            </svg>
          </p>
        </div>
        {selectedItem.length > 0 &&
          selectedItem.map(item => (
            <Chip
              key={item}
              tabIndex={-1}
              label={item}
              onDelete={handleDelete(item)}
            />
          ))}
      </div>

      <div className="modal-actions">
        <Button
          className="cancel"
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button
          className="confirm"
          variant="filled"
          onClick={handleAddTags}
          disabled={disabled}
        >
          Add
        </Button>
      </div>
    </div>
  )
}

export default ModalEditTags
