import React from "react"
import { Button } from "@material-ui/core"
import NoDataImg from "../../images/no-data.webp"
import { navigate } from "gatsby"

const InsufficientData = () => (
  <>
    <p>Uh oh!</p>
    <p>It looks like we don't have enough data to generate this report.</p>
    <p>
      INVOKE requires at least 6 responses in order to protect creators'
      anonymity.
    </p>
    <p>Let's get on more sets together!</p>
    <p>
      <img src={NoDataImg} alt="Insufficient Data" />
    </p>
    <Button
      className="viewer-list"
      variant="contained"
      onClick={() => {
        navigate("/report-dashboard")
      }}
    >
      Back to Dashboard
    </Button>
  </>
)

export default InsufficientData
