import React from "react"
import { Grid } from "@material-ui/core"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import PURPLE from "../../../constant/purpleList"
import valueLookups from "../../../util/lookups"
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const PronounsGenderTab = ({ gender, sexualOrientation, print }) => {
  const genderHeight = print
    ? gender.chart.length * 30
    : gender.chart.length * 46
  const sexualOrientationHeight = print
    ? sexualOrientation.chart.length * 30
    : sexualOrientation.chart.length * 46

  const genderData = {
    labels: gender.chart.map(item => valueLookups(item.name)),
    datasets: [
      {
        data: gender.chart.map(item => item.total),
        backgroundColor: PURPLE,
        barThickness: print ? 30 : 46,
        percentage: gender.chart.map(item => item.percentage),
        datalabels: {
          labels: {
            name: {
              anchor: "end",
              align: "end",
              color: "#000",
              font: {
                size: 12,
                family: "LatoBold",
              },
              formatter: function (_, ctx) {
                return `${valueLookups(
                  ctx.chart.data.labels[ctx.dataIndex]
                )} (${ctx.dataset?.percentage[ctx.dataIndex]?.toFixed(2)}%)`
              },
            },
            value: {
              align: "end",
              anchor: "end",
              color: "#000",
              display: false,
              font: {
                size: 12,
                family: "LatoBold",
              },
              formatter: function (_, ctx) {
                return `${ctx.dataset?.percentage[ctx.dataIndex]?.toFixed(2)}%`
              },
            },
          },
        },
      },
    ],
  }

  const sexualOrientationData = {
    labels: sexualOrientation.chart.map(item => valueLookups(item.name)),
    datasets: [
      {
        data: sexualOrientation.chart.map(item => item.total),
        backgroundColor: PURPLE,
        barThickness: print ? 30 : 46,
        percentage: sexualOrientation.chart.map(item => item.percentage),
        datalabels: {
          labels: {
            name: {
              anchor: "end",
              align: "end",
              color: "#000",
              font: {
                size: 12,
                family: "LatoBold",
              },
              formatter: function (_, ctx) {
                return `${valueLookups(
                  ctx.chart.data.labels[ctx.dataIndex]
                )} (${ctx.dataset?.percentage[ctx.dataIndex]?.toFixed(2)}%)`
              },
            },
            value: {
              align: "end",
              anchor: "end",
              color: "#000",
              display: false,
              font: {
                size: 12,
                family: "LatoBold",
              },
              formatter: function (_, ctx) {
                return `${ctx.dataset?.percentage[ctx.dataIndex]?.toFixed(2)}%`
              },
            },
          },
        },
      },
    ],
  }

  const options = {
    indexAxis: "y",
    showTooltips: false,
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    layout: {
      padding: {
        right: 150,
      },
    },
    responseive: false,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: "#fff",
        anchor: "end",
        align: "start",
        font: {
          size: 13,
          family: "LatoBold",
        },
        formatter: (_, context) => {
          return context.chart.data.labels[context.dataIndex]
        },
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
  }

  return (
    <div className="pronouns-container">
      <Grid container>
        {/* <Grid item xs={12} md={6}>
          <h3>Pronouns</h3>
          <div className="bar-chart-container">
            <Bar
              options={options}
              data={pronounData}
              height={pronounHeight}
              className="pronouns-chart"
            />
          </div>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <h3>Gender Identity</h3>
          <div className="bar-chart-container">
            <Bar
              options={options}
              data={genderData}
              height={genderHeight}
              className="gender-chart"
            />
          </div>
        </Grid>

        <Grid item xs={12} md={6}>
          <h3>Sexual Orientation</h3>
          <div className="bar-chart-container">
            <Bar
              options={options}
              data={sexualOrientationData}
              height={sexualOrientationHeight}
              className="sexual-orientation-chart"
            />
          </div>
        </Grid>
      </Grid>

      <div className="note">
        <p>
          Note: Participants may make multiple selections, so totals may exceed
          100%
        </p>
      </div>
    </div>
  )
}

export default PronounsGenderTab
