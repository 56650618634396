import React from "react"

const AnalysisTab = ({ data }) => (
  <div className="analysis-container">
    {data.map(analysis => (
      <p>{analysis}</p>
    ))}
  </div>
)

export default AnalysisTab
