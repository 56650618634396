import { navigate } from "gatsby"
import JsPDF from "jspdf"
import GREEN from "../constant/greenList"
import LOGO from "../images/invoke_logo3.png"
import Checkmark from "../images/checkmark.png"
import NoCheckmark from "../images/no-checkmark.png"
import valueLookups from "./lookups"
import jobNumber from "./jobNumber"
import { uniq } from "lodash"
const BAR_CHART_WIDTH = 350
const PIE_CHART_WIDTH = 150

export const generatePDF = (
  data,
  id,
  type,
  pieChartCounter,
  setPieChartCounter,
  aggregated,
  roleNames
) => {
  navigate(`/report-dashboard/?id=${id}&type=${type}&print=true`)

  const perChunk = 8

  let multiRacialChartImg = ""
  let asianChartImg = ""
  let hispanicChartImg = "";

  const ethnicityResult = data.ethnicity.chart
    .slice(0, 20)
    .reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

  const asianResult = data.asian.chart
    .slice(0, 12)
    .reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])



  const getHispanicWithValue = data.hispanic.chart.filter(item => item.total > 0)
  const hispanicResult = getHispanicWithValue
    .slice(0, 12)
    .reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

  const multiRacialResult = data.multiRacial.chart
    .slice(0, 12)
    .reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    }, [])

  const disabilityResult = data.disabilityStatus.chart.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    },
    []
  )

  const veteranResult = data.veteranStatus.chart.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    },
    []
  )

  const timer = setTimeout(() => {
    const doc = new JsPDF("p", "px", "letter")
    var width = doc.internal.pageSize.getWidth()
    var lastY = 115
    var breakdown = false
    doc.page = 1

    const header = () => {
      doc.setFillColor(54, 54, 47)
      doc.rect(0, 0, width, 40, "F")
      doc.addImage(LOGO, "JPEG", 20, 12, 50, 20)

      if (doc.page === 1 && !aggregated) {
        doc.setFont("Helvetica", "bold")
        doc.setFontSize(18)
        doc.text(data?.projectInfo?.projectName || "N/A", 20, 65)
        if (data?.projectInfo?.companyName) {
          doc.setFont("Helvetica", "normal")
          doc.setFontSize(11)
          doc.setTextColor(155, 147, 138)
          doc.text("This report commission by: ", 20, 80)
          doc.setFont("Helvetica", "bold")
          doc.setTextColor(0, 0, 0)
          doc.text(data?.projectInfo?.companyName, 120, 80)
        }
        doc.text(`Job #: ${jobNumber(type, id)}`, 20, 93)
      }
    }

    const footer = () => {
      doc.line(20, 550, width - 20, 550)
      doc.setFont("Helvetica", "normal")
      doc.text("CONFIDENTIAL", 20, 570)
      doc.text(width - 25, 570, "" + doc.page)
      doc.page++
    }

    const summaryChart = document.querySelector(".summary-chart")
    const summaryChartImg = summaryChart.toDataURL("image/png", 1.0)

    const langChart = document.querySelector(".lang-chart")
    const langChartImg = langChart.toDataURL("image/png", 1.0)

    const ancestryChart = document.querySelector(".ancestry-chart")
    const ancestryChartImg = ancestryChart.toDataURL("image/png", 1.0)

    const ethnicityChart = document.querySelector(".ethnicity-chart")
    const ethnicityChartImg = ethnicityChart.toDataURL("image/png", 1.0)

    let multiRacialChart
    if (data.multiRacial.chart.length > 0) {
      multiRacialChart = document.querySelector(".multi-racial-chart")
      multiRacialChartImg = multiRacialChart.toDataURL("image/png", 1.0)
    }

    if (data.asian.chart.length > 0) {
      const asianChart = document.querySelector(".asian-chart")
      asianChartImg = asianChart.toDataURL("image/png", 1.0)
    }

    if (data.hispanic.chart.length > 0) {
      const hispanicChart = document.querySelector(".hispanic-chart");
      hispanicChartImg = hispanicChart.toDataURL("img/png", 1.0);
    }
    const genderChart = document.querySelector(".gender-chart")
    const genderChartImg = genderChart.toDataURL("image/png", 1.0)
    const sexualOrientationChart = document.querySelector(
      ".sexual-orientation-chart"
    )
    const sexualOrientationChartImg = sexualOrientationChart.toDataURL(
      "image/png",
      1.0
    )

    const ageChart = document.querySelector(".age-chart")
    const ageChartImg = ageChart.toDataURL("image/png", 1.0)

    const veteranChart = document.querySelector(".veteran-chart")
    const veteranChartImg = veteranChart.toDataURL("image/png", 1.0)

    const disabilityChart = document.querySelector(".disablility-chart")
    const disabilityChartImg = disabilityChart.toDataURL("image/png", 1.0)

    header()

    // Production Detail
    doc.setFont("Helvetica", "bold")
    doc.setFontSize(11)
    doc.text(
      `${aggregated ? "Custom report details" : "Production Details"}`,
      20,
      aggregated ? 65 : 115
    )

    if (aggregated) {
      doc.setTextColor(155, 147, 138)
      doc.text("Project included:", 20, 85)
      doc.setTextColor(0, 0, 0)
      lastY = 85
      data.projectsNames.slice(0, 20).map((p, index) => {
        doc.text(
          `Job #${jobNumber(p.type, p.id)} ${p.name.slice(0, 10)}${
            p.name.length > 10 ? "..." : ""
          }`,
          110,
          85 + index * 10
        )
        lastY = lastY + 10
      })

      doc.setFont("Helvetica", "normal")
      doc.setFontSize(8)
      doc.text(`* Full project list will be on the last page.`, 110, lastY + 5)
    }

    if (data?.projectInfo?.brand) {
      doc.setTextColor(155, 147, 138)
      doc.text("Brand/Studio:", 20, 135)
      doc.setTextColor(0, 0, 0)
      doc.text(data?.projectInfo?.brand || "N/A", 110, 135)
      lastY = 135
    }

    if (data?.projectInfo?.agency) {
      doc.setFont("Helvetica", "bold")
      doc.setTextColor(155, 147, 138)
      doc.text("Agency:", 20, lastY + 20)
      doc.setTextColor(0, 0, 0)
      doc.text(`#${data?.projectInfo?.agency || "N/A"}`, 110, lastY + 20)
      lastY = lastY + 20
    }

    if (data?.projectInfo?.productionCompany) {
      doc.setFont("Helvetica", "bold")
      doc.setTextColor(155, 147, 138)
      doc.text("Production Company:", 20, lastY + 20)
      doc.setTextColor(0, 0, 0)
      doc.text(
        `#${data?.projectInfo?.productionCompany || "N/A"}`,
        110,
        lastY + 20
      )
      doc.setFontSize(10)
      doc.rect(110, lastY + 26, 9, 9)
      doc.addImage(
        data?.projectInfo?.productionCompanyMoc === 0 ? NoCheckmark : Checkmark,
        "JPEG",
        111,
        lastY + 27,
        7,
        7
      )
      doc.setTextColor(147, 152, 157)
      doc.text(`Minority-owned vendor`, 122, lastY + 33)
      lastY = lastY + 30
    }

    doc.setFontSize(11)

    if (parseInt(data?.projectInfo?.budget) > 0) {
      doc.setFont("Helvetica", "bold")
      doc.setTextColor(155, 147, 138)
      doc.text(`Budget:`, 20, lastY + 20)
      doc.setTextColor(0, 0, 0)
      doc.text(`$${data?.projectInfo?.budget || "0"}`, 110, lastY + 20)
      lastY = lastY + 20
    }

    doc.setFont("Helvetica", "bold")
    doc.setTextColor(155, 147, 138)
    doc.text(`Crew size:`, 20, lastY + 20)
    doc.setTextColor(0, 0, 0)
    doc.text(`${data.totalCrew || 0}`, 110, lastY + 20)
    lastY = lastY + 20

    if (
      data?.projectInfo?.city !== undefined &&
      data?.projectInfo?.country !== undefined
    ) {
      doc.setFont("Helvetica", "bold")
      doc.setTextColor(155, 147, 138)
      doc.text(`Location:`, 20, lastY + 20)
      doc.setTextColor(0, 0, 0)

      const locationText = doc.splitTextToSize(
        `${data?.projectInfo?.city}, ${data?.projectInfo?.country || ""}`,
        100
      )

      const locationHeight = doc.getTextDimensions(locationText)

      doc.text(locationText, 110, lastY + 20)

      if (locationHeight.h > 18) {
        lastY = lastY + 40
      } else {
        lastY = lastY + 20
      }
    }

    if (data?.projectInfo?.productionType) {
      doc.setFont("Helvetica", "bold")
      doc.setTextColor(155, 147, 138)
      doc.text(`Type:`, 20, lastY + 20)
      doc.setTextColor(0, 0, 0)
      doc.text(`${data?.projectInfo?.productionType || "N/A"}`, 110, lastY + 20)
      lastY = lastY + 20
    }

    if (!aggregated) {
      doc.setFont("Helvetica", "bold")
      doc.setTextColor(155, 147, 138)
      doc.text(`Start Date:`, 20, lastY + 20)
      doc.setTextColor(0, 0, 0)
      doc.text(`${data?.projectInfo?.startData || "N/A"}`, 110, lastY + 20)
      lastY = lastY + 20

      doc.setFont("Helvetica", "bold")
      doc.setTextColor(155, 147, 138)
      doc.text(`End Date:`, 20, lastY + 20)
      doc.setTextColor(0, 0, 0)
      doc.text(`${data?.projectInfo?.endDate || "N/A"}`, 110, lastY + 20)
      lastY = lastY + 20
    }

    if (data?.projectInfo?.externalTags.length > 0) {
      doc.setFont("Helvetica", "bold")
      doc.setTextColor(155, 147, 138)
      doc.text(`Tags:`, 20, lastY + 20)
      doc.setTextColor(0, 0, 0)
      doc.text(
        `${data?.projectInfo?.externalTags?.toString() || ""}`,
        110,
        lastY + 20,
        {
          maxWidth: 100,
        }
      )
      lastY = lastY + 20
    }

    if (aggregated && data?.externalTags.length > 0) {
      doc.setFont("Helvetica", "bold")
      doc.setTextColor(155, 147, 138)
      doc.text(`Tags:`, 20, lastY + 20)
      doc.setTextColor(0, 0, 0)
      const tagsData = uniq(
        data?.externalTags.map(i => i.toLowerCase())
      )?.toString()
      // let tagsLength = doc.getTextWidth(tagsData)
      doc.text(`${tagsData || ""}`, 110, lastY + 20, {
        maxWidth: 100,
      })

      lastY = lastY + 60
    }

    if (aggregated && roleNames.length > 0) {
      doc.setFont("Helvetica", "bold")
      doc.setTextColor(155, 147, 138)
      doc.text(`Roles:`, 20, lastY + 20)
      doc.setTextColor(0, 0, 0)
      doc.text(`${roleNames.map(i => i)?.toString() || ""}`, 110, lastY + 20, {
        maxWidth: 100,
      })
      lastY = lastY + 20
    }

    if (data?.projectInfo?.customReportNote) {
      doc.setFont("Helvetica", "bold")
      doc.setTextColor(155, 147, 138)
      doc.text(`Notes:`, 20, lastY + 20)
      doc.setTextColor(0, 0, 0)
      doc.text(
        `${data?.projectInfo?.customReportNote || ""}`,
        110,
        lastY + 20,
        {
          maxWidth: 100,
        }
      )
      lastY = lastY + 20
    }

    // Summary
    doc.setFont("Helvetica", "bold")
    doc.text("Summary", 220, aggregated ? 65 : 115)
    doc.setFontSize(11)

    if (roleNames[0] === "All roles") {
      doc.text(
        `This ${aggregated ? "sample set" : "production"} had a`,
        220,
        aggregated ? 85 : 135
      )
      doc.setTextColor(105, 211, 186)
      doc.text(`${data.participationRate}%`, 308, aggregated ? 85 : 135)
      doc.setTextColor(0, 0, 0)
      doc.text("overall participation rate.", 340, aggregated ? 85 : 135)
    }

    if (
      roleNames[0] === "All roles" &&
      (type === "PROACTIVE" || type === "proactive")
    ) {
      doc.text("The INVOKE team spoke with", 220, aggregated ? 105 : 155)
      doc.setTextColor(105, 211, 186)
      doc.text(`${data.engagement}%`, 338, aggregated ? 105 : 155)
      doc.setTextColor(0, 0, 0)
      doc.text("of all crew.", 370, aggregated ? 105 : 155)

      doc.text("Of those,", 220, aggregated ? 115 : 165)
      doc.setTextColor(105, 211, 186)
      doc.text(
        `${data.engagementParticipationRate}%`,
        258,
        aggregated ? 115 : 165
      )
      doc.setTextColor(0, 0, 0)
      doc.text("took the survey", 282, aggregated ? 115 : 165)
    }

    const typePos = type === "PROACTIVE" || type === "proactive" ? 0 : 30

    doc.addImage(
      summaryChartImg,
      "JPEG",
      250,
      aggregated ? 165 - typePos : 195 - typePos,
      PIE_CHART_WIDTH,
      PIE_CHART_WIDTH
    )

    doc.setTextColor(0, 0, 0)
    doc.text("At least", 220, aggregated ? 345 : 360)
    doc.setTextColor(105, 211, 186)
    doc.text(
      `${data.underRepresented.underPercentage}% of creators`,
      256,
      aggregated ? 345 : 360
    )
    doc.setTextColor(0, 0, 0)
    doc.text(`on this project identified`, 340, aggregated ? 345 : 360)
    doc.text(
      `with at least 1 underrepresented background`,
      220,
      aggregated ? 355 : 372
    )

    doc.setFont("Helvetica", "normal")
    doc.setFontSize(8)

    doc.text(
      `Crew members who declined to participate are accounted for as "No data"`,
      220,
      383
    )

    doc.setFillColor(116, 214, 191)
    doc.circle(230, 400, 5, "F")
    doc.setFontSize(11)
    doc.text(`Underrepresented`, 240, 403)
    doc.text(
      `${data.underRepresented?.underPercentage?.toFixed(2)}%`,
      400,
      403,
      { align: "right" }
    )

    doc.setFillColor(165, 228, 212)
    doc.circle(230, 420, 5, "F")

    doc.text(`Not underrepresented`, 240, 423)
    doc.text(
      `${data.underRepresented?.notUnderPercentage?.toFixed(2)}%`,
      400,
      423,
      { align: "right" }
    )

    doc.setFillColor(202, 243, 233)
    doc.circle(230, 440, 5, "F")

    doc.text(`No data`, 240, 443)
    doc.text(
      `${data.underRepresented?.noData?.percentage?.toFixed(2)}%`,
      400,
      443,
      { align: "right" }
    )

    footer()

    doc.addPage()

    header()

    // Ethnicity
    doc.setFont("Helvetica", "bold")
    doc.setFontSize(12)
    doc.text("Ethnicity", 20, 70)

    doc.setFontSize(10)

    if (data?.ethnicity?.count > 0) {
      doc.text("Participants indentified across", 20, 85)
      doc.setTextColor(105, 211, 186)
      doc.text(
        `${data?.ethnicity?.count} different ethnic backgrounds`,
        132,
        85
      )

      doc.addImage(
        ethnicityChartImg,
        "JPEG",
        40,
        115,
        PIE_CHART_WIDTH,
        PIE_CHART_WIDTH
      )

      doc.setFont("Helvetica", "normal")
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(8)
      doc.text(
        `* Participants who select multiple ethnicities are noted as "multi-racial"`,
        25,
        290
      )

      doc.setFontSize(10)

      ethnicityResult.map(list =>
        list.map((i, index) => {
          doc.setFillColor(GREEN[index])
          doc.circle(240, 100 + pieChartCounter.ethnicity * 20, 5, "F")

          doc.text(
            valueLookups(i.name),
            250,
            102 + pieChartCounter.ethnicity * 20,
            {
              maxWidth: 150,
            }
          )
          doc.text(
            `${i?.percentage?.toFixed(2)}%`,
            415,
            102 + pieChartCounter.ethnicity * 20,
            { align: "right" }
          )
          setPieChartCounter(prev => ({
            ...prev,
            ethnicity: pieChartCounter.ethnicity++,
          }))
        })
      )
    } else {
      doc.text("This information is not available through this survey", 20, 85)
    }

    footer()

    if (data.multiRacial.chart.length > 0 || data.asian.chart.length > 0) {
      doc.addPage()

      header()

      
      breakdown = true
      doc.setFont("Helvetica", "bold")
      doc.setFontSize(14)
      doc.text("Additional Ethnicity Breakdown", 20, 70)
      
      if (data.multiRacial.chart.length > 0) {
        // Multi-racial
        doc.setFont("Helvetica", "bold")
        doc.setFontSize(12)
        doc.text("Multi-racial", 20, 95)

        doc.addImage(
          multiRacialChartImg,
          "JPEG",
          40,
          120,
          BAR_CHART_WIDTH,
          (multiRacialChart.height * BAR_CHART_WIDTH) / multiRacialChart.width
        )

        doc.setFont("Helvetica", "normal")
        doc.setFontSize(10)

        doc.text(
          "Note: Participants may make multiple selections, so totals may exceed 100%",
          20,
          540
        )
      }

      if (data.asian.chart.length > 0) {
        doc.addPage()
        header()
        doc.setFont("Helvetica", "bold")
        doc.setFontSize(12)
        doc.text("Asian", 20, 70)
        doc.addImage(
          asianChartImg,
          "JPEG",
          40,
          120,
          PIE_CHART_WIDTH,
          PIE_CHART_WIDTH
        )

        doc.setFont("Helvetica", "normal")
        doc.setFontSize(10)
        asianResult.map(list =>
          list.map((i, index) => {
            doc.setFillColor(GREEN[index])
            doc.circle(
              20,
              290 + pieChartCounter.asian * 20,
              5,
              "F"
            )

            doc.text(valueLookups(i.name), 30, 292 + pieChartCounter.asian * 20, {
              maxWidth: 140,
            })
            doc.text(
              `${i?.percentage?.toFixed(2)}%`,
              205,
              292 + pieChartCounter.asian * 20,
              { align: "right" }
            )
            setPieChartCounter(prev => ({
              ...prev,
              asian: pieChartCounter.asian++,
            }))
          })
        )
      }
    }

    footer()

    

    if (data.hispanic.chart.length > 0) {
      doc.addPage()

      header()
      
      doc.setFont("Helvetica", "bold")
      doc.setFontSize(12)
      doc.text("Hispanic/Lantinx",  20 , 95)
      doc.addImage(
        hispanicChartImg,
        "JPEG",
        40,
        120,
        PIE_CHART_WIDTH,
        PIE_CHART_WIDTH
      )
      doc.setFont("Helvetica", "normal")
      doc.setTextColor(0, 0, 0)
      doc.setFontSize(8)
      doc.text(
        `* Hispanic/Latinx is a discontinued option for the future surveys."`,
        25,
        290
      )
      doc.setFont("Helvetica", "normal")
      doc.setFontSize(10)
      hispanicResult.map(list =>
        list.map((i, index) => {
          doc.setFillColor(GREEN[index])
          doc.circle(
            20,
            290 + pieChartCounter.hispanic * 20,
            5,
            "F"
          )

          doc.text(
            valueLookups(i.name),
            30,
            292 + pieChartCounter.hispanic * 20,
            {
              maxWidth: 150,
            }
          )
          doc.text(
            `${i?.percentage?.toFixed(2)}%`,
            205,
            292 + pieChartCounter.hispanic * 20,
            { align: "right" }
          )
          setPieChartCounter(prev => ({
            ...prev,
            hispanic: pieChartCounter.hispanic++,
          }))
        })
      )
      footer()
    }
    
    // Languages
    doc.addPage()

    header()

    doc.setFont("Helvetica", "bold")
    doc.setFontSize(12)
    doc.text("Languages", 20, 70)
    doc.setFontSize(10)

    if (data?.language?.count > 0) {
      doc.text("There were", 20, 85)
      doc.setTextColor(105, 211, 186)
      doc.text(`${data?.language?.count} different languages`, 65, 85)
      doc.setTextColor(0, 0, 0)
      doc.text(
        `represented on ${aggregated ? "these projects" : "this project"}`,
        150,
        85
      )
      doc.addImage(
        langChartImg,
        "JPEG",
        20,
        110,
        BAR_CHART_WIDTH,
        (langChart.height * BAR_CHART_WIDTH) / langChart.width
      )

      doc.setFont("Helvetica", "normal")
      doc.setFontSize(10)
      doc.text("Note: Displaying top 20 on the chart.", 20, 540)
    } else {
      doc.text("This information is not available through this survey", 20, 85)
    }

    footer()
    doc.addPage()

    header()

    // Ancestries
    doc.setFont("Helvetica", "bold")
    doc.setFontSize(12)
    doc.text("Ancestries", 20, 70)

    doc.setFontSize(10)

    if (data?.ancestry?.count > 0) {
      doc.text("There were", 20, 85)
      doc.setTextColor(105, 211, 186)
      doc.text(
        `${data?.ancestry?.count} different ancestral backgrounds`,
        62,
        85
      )
      doc.setTextColor(0, 0, 0)
      doc.text(
        `represented on ${aggregated ? "these projects" : "this project"}`,
        190,
        85
      )
      doc.addImage(
        ancestryChartImg,
        "JPEG",
        20,
        110,
        BAR_CHART_WIDTH,
        (ancestryChart.height * BAR_CHART_WIDTH) / ancestryChart.width
      )

      doc.setFont("Helvetica", "normal")
      doc.setFontSize(10)
      doc.text("Note: Displaying top 20 on the chart.", 20, 540)
    } else {
      doc.text("This information is not available through this survey", 20, 85)
    }

    footer()

    doc.addPage()

    header()

    // Pronouns
    // doc.setFont("Helvetica", "bold")
    // doc.setFontSize(12)
    // doc.text("Pronouns", 20, 70)

    // doc.addImage(
    //   pronounsChartImg,
    //   "JPEG",
    //   20,
    //   85,
    //   BAR_CHART_WIDTH,
    //   (pronounsChart.height * BAR_CHART_WIDTH) / pronounsChart.width
    // )

    doc.setFont("Helvetica", "bold")
    doc.setFontSize(12)
    doc.text("Gender Identity", 20, 70)
    doc.addImage(
      genderChartImg,
      "JPEG",
      20,
      85,
      BAR_CHART_WIDTH,
      (genderChart.height * BAR_CHART_WIDTH) / genderChart.width
    )

    doc.setFont("Helvetica", "normal")
    doc.setFontSize(10)
    doc.text(
      "Note: Participants may make multiple selections, so totals may exceed 100%",
      20,
      540
    )
    footer()
    doc.addPage()
    header()

    // Sexual Orientation
    doc.setFont("Helvetica", "bold")
    doc.setFontSize(12)
    doc.text("Sexual Orientation", 20, 70)

    doc.addImage(
      sexualOrientationChartImg,
      "JPEG",
      20,
      85,
      BAR_CHART_WIDTH,
      (sexualOrientationChart.height * BAR_CHART_WIDTH) /
        sexualOrientationChart.width
    )

    doc.text(
      "Age",
      20,
      (sexualOrientationChart.height * BAR_CHART_WIDTH) /
        sexualOrientationChart.width +
        130
    )
    doc.addImage(
      ageChartImg,
      "JPEG",
      20,
      (sexualOrientationChart.height * BAR_CHART_WIDTH) /
        sexualOrientationChart.width +
        150,
      BAR_CHART_WIDTH,
      (ageChart.height * BAR_CHART_WIDTH) / ageChart.width
    )

    doc.setFont("Helvetica", "normal")
    doc.setFontSize(10)
    doc.text(
      "Note: Participants may make multiple selections, so totals may exceed 100%",
      20,
      540
    )
    footer()
    doc.addPage()

    header()

    doc.setFont("Helvetica", "bold")
    doc.setFontSize(12)
    doc.text("Veteran Status", 20, 70)

    doc.addImage(
      veteranChartImg,
      "JPEG",
      40,
      100,
      PIE_CHART_WIDTH,
      PIE_CHART_WIDTH
    )

    doc.setFont("Helvetica", "normal")
    doc.setFontSize(10)

    veteranResult.map(list =>
      list.map((i, index) => {
        doc.setFillColor(GREEN[index])
        doc.circle(20, 270 + pieChartCounter.veteran * 20, 5, "F")

        doc.text(
          valueLookups(`v-${i.name}`),
          30,
          272 + pieChartCounter.veteran * 20,
          {
            maxWidth: 150,
          }
        )
        doc.text(
          `${i?.percentage?.toFixed(2)}%`,
          205,
          272 + pieChartCounter.veteran * 20,
          { align: "right" }
        )
        setPieChartCounter(prev => ({
          ...prev,
          veteran: pieChartCounter.veteran++,
        }))
      })
    )

    doc.setFont("Helvetica", "bold")
    doc.setFontSize(12)
    doc.text("Disability Status", 240, 70)
    doc.addImage(
      disabilityChartImg,
      "JPEG",
      265,
      100,
      PIE_CHART_WIDTH,
      PIE_CHART_WIDTH
    )

    doc.setFont("Helvetica", "normal")
    doc.setFontSize(10)

    disabilityResult.map(list =>
      list.map((i, index) => {
        doc.setFillColor(GREEN[index])
        doc.circle(250, 270 + pieChartCounter.disability * 20, 5, "F")

        doc.text(
          valueLookups(`d-${i.name}`),
          260,
          272 + pieChartCounter.disability * 20,
          {
            maxWidth: 150,
          }
        )
        doc.text(
          `${i?.percentage?.toFixed(2)}%`,
          425,
          272 + pieChartCounter.disability * 20,
          { align: "right" }
        )
        setPieChartCounter(prev => ({
          ...prev,
          disability: pieChartCounter.disability++,
        }))
      })
    )
    footer()

    if (aggregated) {
      doc.addPage()
      header()

      doc.setTextColor(155, 147, 138)
      doc.text("Project included:", 20, 85)
      doc.setTextColor(0, 0, 0)
      lastY = 85
      data.projectsNames.map((p, index) => {
        doc.text(
          `Job #${jobNumber(p.type, p.id)} ${p.name}`,
          110,
          85 + index * 10
        )
        lastY = lastY + 10
      })

      footer()
    }

    if (data?.selectedFeedback?.length > 0) {
      doc.addPage()
      header()
      doc.setTextColor(0, 0, 0)
      doc.setFont("Helvetica", "bold")
      doc.setFontSize(12)
      doc.text("Crew Feedback", 20, 70)
      doc.setFontSize(10)
      lastY = 100
      data?.selectedFeedback?.map(feedback => {
        const feedbackText = doc.splitTextToSize(`"${feedback}"`, 400)
        const feedbackHeight = doc.getTextDimensions(feedbackText)

        doc.text(feedbackText, 20, lastY)
        lastY = lastY + feedbackHeight.h + 10
      })
      footer()
    }

    if (data?.feedbackAnalysis?.length > 0) {
      doc.addPage()
      header()
      doc.setTextColor(0, 0, 0)
      doc.setFont("Helvetica", "bold")
      doc.setFontSize(12)
      doc.text("FREE THE WORK Analysis", 20, 70)
      doc.setFontSize(10)

      data?.feedbackAnalysis?.map(analysis => {
        doc.text(analysis, 20, 100)
      })

      footer()
    }

    doc.addPage()
    header()
    doc.setTextColor(0, 0, 0)
    doc.setFont("Helvetica", "normal")
    doc.setFontSize(14)

    let text =
      "INVOKE Insights, Powered by the non-profit FREE THE WORK, has safely and \n" +
      "anonymously collected the results presented in this report. \n\n" +
      "With every production, information is collected judgment-free, focused solely on helping \n" +
      "our partners and collaborators gain a clearer picture of where they are and the path ahead. \n\n" +
      "There are no next steps on individual production results. Please discuss with INVOKE/FTW \n" +
      "how to collaborate on future productions. The insights received support INVOKE's \n" +
      "mission of improving the production industry through data-driven advocacy."

    doc.text(text, 20, 90)

    footer()

    doc.save(`report-${Math.floor(Date.now() / 1000)}.pdf`)
    setPieChartCounter({
      ethnicity: 1,
      multiRacial: 1,
      asian: 1,
      veteran: 1,
      disability: 1,
    })
    navigate(-1)
  }, 1500)
  return () => clearTimeout(timer)
}
