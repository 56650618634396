import moment from "moment"
import { json2csv } from "json-2-csv"

// eslint-disable-next-line
export default (JSONData, ReportTitle) => {
  var arrData = typeof JSONData !== "object" ? JSON.parse(JSONData) : JSONData

  let CSV = ""
  CSV += ReportTitle + "\r\n\n"

  const options = {
    emptyFieldValue: "",
    expandArrayObjects: false,
  }

  json2csv(
    arrData,
    (err, csv) => {
      var fileName = ReportTitle.replace(/ /g, "_")
      var uri = "data:text/csv;charset=utf-8," + escape(csv)
      var link = document.createElement("a")
      link.href = uri
      link.style = "visibility:hidden"
      link.download = fileName + ".csv"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    options
  )

  if (CSV === "") {
    alert("Invalid data")
    return
  }
}
