import {
  languageLookup,
  ancestryLookup,
  ethnicityLookup,
  pronounLookup,
  genderLookup,
  sexualOrientationLookup,
  ageLookup,
  disabilityLookup,
  veteranLookup,
} from "../util/csvLookups"
import jobNumber from "./jobNumber"

export default data => {
  var languages = data.language.chart.reduce(
    (obj, item) => ((obj[languageLookup(item.name)] = item.total), obj),
    {}
  )

  var ancestries = data.ancestry.chart.reduce(
    (obj, item) => ((obj[ancestryLookup(item.name)] = item.total), obj),
    {}
  )

  var ethnicities = data.ethnicity.chart.reduce(
    (obj, item) => (
      (obj[ethnicityLookup(item.name)] = `${item.percentage.toFixed(2)}%`), obj
    ),
    {}
  )

  // var pronouns = data.pronoun.chart.reduce(
  //   (obj, item) => (
  //     (obj[pronounLookup(item.name)] = `${item.percentage.toFixed(2)}%`), obj
  //   ),
  //   {}
  // )

  var genders = data.gender.chart.reduce(
    (obj, item) => (
      (obj[genderLookup(item.name)] = `${item.percentage.toFixed(2)}%`), obj
    ),
    {}
  )

  var sexualOrientations = data.sexualOrientation.chart.reduce(
    (obj, item) => (
      (obj[sexualOrientationLookup(item.name)] = `${item.percentage.toFixed(
        2
      )}%`),
      obj
    ),
    {}
  )

  var ages = data.age.chart.reduce(
    (obj, item) => ((obj[ageLookup(item.name)] = item.count), obj),
    {}
  )

  var veteranStatuses = data.veteranStatus.chart.reduce(
    (obj, item) => (
      (obj[veteranLookup(item.name)] = `${item.percentage.toFixed(2)}%`), obj
    ),
    {}
  )

  var disabilityStatuses = data.disabilityStatus.chart.reduce(
    (obj, item) => (
      (obj[disabilityLookup(item.name)] = `${item.percentage.toFixed(2)}%`), obj
    ),
    {}
  )

  languages = {
    "Total Languages": data.language.count,
    ...languages,
  }

  ancestries = {
    "Total ancestries": data.ancestry.count,
    ...ancestries,
  }
  ethnicities = {
    "Total ethnicities": data.ethnicity.count,
    ...ethnicities,
  }

  // pronouns = {
  //   Pronouns: "",
  //   ...pronouns,
  // }

  genders = {
    "Gender Identity": "",
    ...genders,
  }

  sexualOrientations = {
    "Sexual orientation": "",
    ...sexualOrientations,
  }

  ages = {
    Age: "",
    ...ages,
  }

  veteranStatuses = {
    "Veteran status": "",
    ...veteranStatuses,
  }

  disabilityStatuses = {
    "Disability status": "",
    ...disabilityStatuses,
  }

  let aggregated = ""
  const projects = []

  if (data.projectsNames) {
    data.projectsNames.map(p => {
      projects.push(`#${jobNumber(p.type, p.id)} - ${p.name}`)
    })

    aggregated = {
      "Project included": projects.toString().replaceAll(',', ', '),
    }
  }

  const topData = {
    "Crew size": data.totalCrew,
    "Participation rate": `${data.participationRate.toFixed(2)}%`,
    Underrepresented: `${data.underRepresented.underPercentage.toFixed(2)}%`,
    "Not underrepresented": `${data.underRepresented.notUnderPercentage.toFixed(
      2
    )}%`,
    "No data": `${data.underRepresented.noData.percentage.toFixed(2)}%`,
  }

  const csvFormat = {
    ...topData,
    ...languages,
    ...ancestries,
    ...ethnicities,
    // ...pronouns,
    ...genders,
    ...sexualOrientations,
    ...ages,
    ...veteranStatuses,
    ...disabilityStatuses,
    ...aggregated,
  }

  return csvFormat
}
