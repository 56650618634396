export const languageLookup = value => {
  switch (value) {
    case "Prefer not to say":
      return "Prefer not to say (L)"
    case "N/A":
      return "No data (L)"
    case "Not Listed":
      return "Not Listed (L)"
    default:
      return `${value} (L)`
  }
}

export const ancestryLookup = value => {
  switch (value) {
    case "Prefer not to say":
      return "Prefer not to say (A)"
    case "N/A":
      return "No data (A)"
    case "Not Listed":
      return "Not Listed (A)"
    default:
      return `${value} (A)`
  }
}

export const ethnicityLookup = value => {
  switch (value) {
    case "Prefer not to say":
      return "Prefer not to say (E)"
    case "N/A":
      return "No data (E)"
    case "Not Listed":
      return "Not Listed (E)"
    default:
      return value
  }
}

export const pronounLookup = value => {
  switch (value) {
    case "he/him":
      return "He/Him"
    case "she/her":
      return "She/Her"
    case "they/them":
      return "They/Them"
    case "ze/zir":
      return "Ze/Zir"
    case "xe/xir":
      return "Xe/Xir"
    case "Prefer not to say":
      return "Prefer not to say (P)"
    case "N/A":
      return "No data (P)"
    case "Not Listed":
      return "Not Listed (P)"
    default:
      return value
  }
}

export const genderLookup = value => {
  switch (value) {
    case "Prefer not to say":
      return "Prefer not to say (G)"
    case "N/A":
      return "No data (G)"
    case "Not Listed":
      return "Not Listed (G)"
    default:
      return value
  }
}

export const sexualOrientationLookup = value => {
  switch (value) {
    case "Prefer not to say":
      return "Prefer not to say (S)"
    case "N/A":
      return "No data (S)"
    case "Not Listed":
      return "Not Listed (S)"
    default:
      return value
  }
}

export const ageLookup = value => {
  switch (value) {
    case "Prefer not to say":
      return "Prefer not to say (Age)"
    case "N/A":
      return "No data (Age)"
    case "Not Listed":
      return "Not Listed (Age)"
    default:
      return value
  }
}

export const veteranLookup = value => {
  switch (value) {
    case "do":
      return "Military veteran"
    case "do not":
      return "Not a veteran"
    case "Prefer not to say":
      return "Prefer not to say (V)"
    case "N/A":
      return "No data (V)"
    default:
      return value
  }
}

export const disabilityLookup = value => {
  switch (value) {
    case "do":
      return "Lives with a disability"
    case "do not":
      return "No disability"
    case "Prefer not to say":
      return "Prefer not to say (D)"
    case "N/A":
      return "No data (D)"
    default:
      return value
  }
}
