import React, { useState, useEffect } from "react"
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  Select,
  TextField,
  MenuItem,
} from "@material-ui/core"
import Autocomplete from "@material-ui/lab/Autocomplete"
import CloseIcon from "@material-ui/icons/Close"
import { makeStyles } from "@material-ui/core/styles"
import { flatten, uniq } from "lodash"
import lookups from "../../util/lookups"
import { navigate } from "gatsby"

const useStyles = makeStyles(theme => ({
  select: {
    minWidth: 450,
    "&:before, &:after": {
      borderBottom: "none !important",
    },
  },
  tags: {
    marginTop: 15,
    maxWidth: 450,
  },
}))

const CustomReport = ({ setOpen, reportTypeList, tagsList }) => {
  const classes = useStyles()
  const [type, setType] = useState("all")
  const [proactiveTags, setProactiveTags] = useState(
    tagsList.filter(i => i.type === "proactive")
  )
  const [preEnrollmentTags, setPreEnrollmentTags] = useState(
    tagsList.filter(i => i.type === "pre-enrollment")
  )
  const [biddingTags, setBiddingTags] = useState(
    tagsList.filter(i => i.type === "bidding")
  )
  const [retroactiveTags, setRetroactiveTags] = useState(
    tagsList.filter(i => i.type === "retroactive")
  )
  const [allTags, setAllTags] = useState(
    uniq(flatten(tagsList.map(i => i.tags)))
  )
  const [selectedTags, setSelectedTags] = useState([])

  useEffect(() => {
    setProactiveTags(uniq(flatten(proactiveTags.map(i => i.tags))))
    setPreEnrollmentTags(uniq(flatten(preEnrollmentTags.map(i => i.tags))))
    setBiddingTags(uniq(flatten(biddingTags.map(i => i.tags))))
    setRetroactiveTags(uniq(flatten(retroactiveTags.map(i => i.tags))))
  }, [])

  useEffect(() => {
    setSelectedTags([])
  }, [type])

  const generateReport = () => {
    localStorage.setItem("aggregatedTags", selectedTags)
    navigate(`/report-dashboard/?aggregated=true&type=${type}&print=false`)
  }

  return (
    <div className="custom-report">
      <div className="head">
        <IconButton onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
        <h3>Create custom report</h3>
      </div>
      <FormControl variant="outlined" className={classes.select}>
        <InputLabel>Select report type *</InputLabel>
        <Select
          label="Select report type *"
          onChange={e => {
            setType(e.target.value)
          }}
          value={type}
        >
          <MenuItem value="all">All types</MenuItem>
          {reportTypeList.length > 0 &&
            reportTypeList.map(i => (
              <MenuItem value={i.type}>{lookups(i.type)}</MenuItem>
            ))}
        </Select>
      </FormControl>

      <Autocomplete
        className={classes.tags}
        multiple
        limitTags={3}
        value={selectedTags}
        onChange={(_, newValue) => {
          setSelectedTags(newValue)
        }}
        options={
          type === "all"
            ? allTags
            : type === "proactive"
            ? proactiveTags
            : type === "pre-enrollment"
            ? preEnrollmentTags
            : type === "bidding"
            ? biddingTags
            : retroactiveTags
        }
        renderInput={params => (
          <TextField {...params} variant="outlined" placeholder="Select tags" />
        )}
      />

      <p className="note">
        If no tags selected, all reports within this category will be included.
      </p>

      <div className="actions modal-actions ">
        <Button variant="contained" onClick={() => setOpen(false)}>
          Cancel
        </Button>
        <Button
          variant="contained"
          className="confirm"
          onClick={generateReport}
        >
          Generate report
        </Button>
      </div>
    </div>
  )
}

export default CustomReport
