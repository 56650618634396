const GREEN = [
  "#3C645A",
  "#517C72",
  "#45A890",
  "#74D6BF",
  "#A5E4D4",
  "#CAF3E9",
  "#DEF9F2",
  "#F0FEFA",
]

export default GREEN
