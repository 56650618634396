export default (type, id) => {
  switch (type) {
    case "PROACTIVE":
      return `10${id}`
    case "PRE-ENROLLMENT":
      return `20${id}`
    case "BIDDING":
      return `30${id}`
    case "RETROACTIVE":
      return `40${id}`
    default:
      return ""
  }
}
