export default data => {
  const customQuestions = data?.customQuestions
  const maxLength = Math.max(
    ...customQuestions.map(({ answer }) => answer.length)
  )
  const csvFormat = []

  for (let i = 0; i < maxLength; i++) {
    const rowData = {}
    customQuestions.forEach(({ question, answer }) => {
      if (i < answer.length) {
        rowData[question] = answer[i]
      }
    })
    csvFormat.push(rowData)
  }

  return csvFormat
}
