import React, { useState } from "react"
import {
  Chip,
  Dialog,
  DialogContent,
  Snackbar as MaterialSnackbar,
} from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"
import { addExternalTags } from "../../../httpRequests/report"
import ModalEditTags from "../ModalEditTags"
import { ReportModal } from "../style"
import Checkmark from "../../../images/checkmark.png"
import NoCheckmark from "../../../images/no-checkmark.png"
import jobNumber from "../../../util/jobNumber"
const ProductionDetail = ({
  projectInfo,
  totalCrew,
  id,
  type,
  setLoading,
  setErrorOpen,
  errorOpen,
  setErrorMessage,
  errorMessage,
  aggregated,
  externalTags,
  customReportNote,
  projectsNames,
  roleNames,
  noDataCrew,
  refetch,
  setRefetch,
}) => {
  const [open, setOpen] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [success, setSuccess] = useState("")
  const [message, setMessage] = useState("")

  return (
    <div className="production-details">
      <h3>{aggregated ? "Custom report details" : "Production Details"}</h3>

      <div className="details">
        {aggregated && (
          <div className="detail-item">
            <p>Project included:</p>
            <span>
              <ul>
                {projectsNames &&
                  projectsNames.length > 0 &&
                  projectsNames.map((project, index) => (
                    <li key={index}>
                      <i>Job #{jobNumber(project.type, project.id)}</i>{" "}
                      {project.name}
                    </li>
                  ))}
              </ul>
            </span>
          </div>
        )}

        {projectInfo?.brand && (
          <div className="detail-item">
            <p>Brand/Studio:</p>
            <span>{projectInfo?.brand || "N/A"}</span>
          </div>
        )}

        {projectInfo?.agency && (
          <div className="detail-item">
            <p>Agency:</p>
            <span>{`#${projectInfo?.agency}` || "N/A"}</span>
          </div>
        )}

        {projectInfo?.productionCompany && (
          <div className="detail-item">
            <p>Production Company:</p>
            <span>
              {`#${projectInfo?.productionCompany}` || "N/A"}

              <div className="moc">
                <br />
                <img
                  src={
                    projectInfo?.productionCompanyMoc === 0
                      ? NoCheckmark
                      : Checkmark
                  }
                  alt="Check"
                />
                Minority-owned vendor
              </div>
            </span>
          </div>
        )}

        {parseInt(projectInfo?.budget) > 0 && (
          <div className="detail-item">
            <p>Budget:</p>
            <span>{`$${projectInfo?.budget || 0}`}</span>
          </div>
        )}

        <div className="detail-item">
          <p>Crew size:</p>
          <span>{noDataCrew > 0 ? noDataCrew : totalCrew}</span>
        </div>

        {projectInfo?.city !== undefined && projectInfo?.country !== undefined && (
          <div className="detail-item">
            <p>Location:</p>
            <span>
              {projectInfo?.city} {projectInfo?.country}
            </span>
          </div>
        )}

        {projectInfo?.productionType && (
          <div className="detail-item">
            <p>Type:</p>
            <span>{projectInfo?.productionType || "N/A"}</span>
          </div>
        )}

        {!aggregated && (
          <>
            <div className="detail-item">
              <p>Start Date:</p>
              <span>{projectInfo?.startData}</span>
            </div>

            <div className="detail-item">
              <p>End Date:</p>
              <span>{projectInfo?.endDate}</span>
            </div>
          </>
        )}

        <div className="detail-item tags-container">
          <p>Tags:</p>
          {aggregated ? (
            <span>
              {externalTags.length > 0 &&
                externalTags.map((tag, index) => (
                  <Chip key={index} size="small" label={tag} />
                ))}
            </span>
          ) : (
            <span>
              {projectInfo?.externalTags.length > 0 &&
                projectInfo?.externalTags.map((tag, index) => (
                  <Chip
                    key={index}
                    size="small"
                    label={tag}
                    onDelete={async () => {
                      setLoading(true)
                      try {
                        const response = await addExternalTags({
                          id: parseInt(id),
                          type: type,
                          tags: projectInfo.externalTags.filter(t => t !== tag),
                        })

                        if (response.data.statusCode === 200) {
                          setRefetch(!refetch)
                        } else {
                          setErrorOpen(true)
                          setErrorMessage(response.data.devMessage)
                          setLoading(false)
                        }
                      } catch (error) {
                        setLoading(false)
                        if (
                          error.response.data.devMessage.includes("Method Not")
                        ) {
                          setErrorOpen(true)
                          setErrorMessage(
                            "You don't have a permission to perform this action."
                          )
                        }
                      }
                    }}
                  />
                ))}
              <div className="add-tag" onClick={() => setOpen(true)}>
                + Add Tags
              </div>
            </span>
          )}
        </div>

        {aggregated && (
          <div className="detail-item tags-container">
            <p>Roles:</p>
            <span>
              {roleNames.length > 0 &&
                roleNames.map((role, index) => (
                  <Chip key={index} size="small" label={role} />
                ))}
            </span>
          </div>
        )}

        {!aggregated && customReportNote && (
          <div className="detail-item">
            <p>Notes:</p>
            <span>{customReportNote}</span>
          </div>
        )}
      </div>

      <Dialog open={open} maxWidth="md" onClose={() => setOpen(false)}>
        <ReportModal>
          <DialogContent>
            <ModalEditTags
              setOpen={setOpen}
              tags={projectInfo?.externalTags}
              setLoading={setLoading}
              reportId={id}
              reportType={type}
              setSnackbarOpen={setSnackbarOpen}
              setSuccess={setSuccess}
              setMessage={setMessage}
              setErrorOpen={setErrorOpen}
              setErrorMessage={setErrorMessage}
              refetch={refetch}
              setRefetch={setRefetch}
              detail
            />
          </DialogContent>
        </ReportModal>
      </Dialog>
      <MaterialSnackbar
        open={errorOpen || snackbarOpen}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        autoHideDuration={5000}
      >
        <MuiAlert
          elevation={6}
          variant="filled"
          onClose={() => setErrorOpen(false)}
          severity={success !== "" ? success : "error"}
        >
          {message !== "" ? message : errorMessage}
        </MuiAlert>
      </MaterialSnackbar>
    </div>
  )
}

export default ProductionDetail
