import React from "react"
import { Button } from "@material-ui/core"
import { archivedReport } from "../../httpRequests/report"

const ModalArchive = ({
  setOpen,
  setLoading,
  reportId,
  reportType,
  setSnackbarOpen,
  setSuccess,
  setMessage,
}) => {
  const confirmArchive = async () => {
    setLoading(true)
    setOpen(false)

    try {
      const response = await archivedReport({
        id: parseInt(reportId),
        type: reportType,
      })

      if (response.data.statusCode === 200) {
        setSnackbarOpen(true)
        setSuccess("success")
        setMessage("The report has been archived successfully!")
      } else {
        setSnackbarOpen(false)
        setSuccess("error")
        setMessage("Oops! Please try again.")
      }
    } catch (error) {
      setLoading(false)
      if (error.response.data.devMessage.includes("Method Not")) {
        setSnackbarOpen(true)
        setSuccess("error")
        setMessage("You don't have a permission to perform this action.")
      }
    }

    setLoading(false)
  }
  return (
    <div className="archive">
      <h3>Are you sure you want to archive this report?</h3>
      <p>
        This will only remove the report from your dashboard.
        <br />
        To remove access to all of your users, contact your Admin.
      </p>
      <div className="modal-actions">
        <Button
          className="cancel"
          variant="outlined"
          onClick={() => setOpen(false)}
        >
          Cancel
        </Button>
        <Button className="confirm" variant="filled" onClick={confirmArchive}>
          Confirm
        </Button>
      </div>
    </div>
  )
}

export default ModalArchive
