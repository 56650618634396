import axios from "axios"
import { API_ROOT, ENV } from "gatsby-env-variables"

const config = {
  headers: {
    Authorization:
      typeof window !== "undefined" && localStorage.getItem("insightToken"),
  },
}

export const getReportDashboard = (page, row, sortBy, orderBy, filterBy) =>
  axios.get(
    `${API_ROOT}${ENV}/report/get?page=${page}&rows=${row}&sortBy=${sortBy}&orderBy=${orderBy}&filterBy=${filterBy}`,
    config
  )

export const archivedReport = params =>
  axios.post(`${API_ROOT}${ENV}/external-user/archive-report`, params, config)

export const addExternalTags = params =>
  axios.post(
    `${API_ROOT}${ENV}/external-user/add-external-tags`,
    params,
    config
  )

export const getChart = (id, type) =>
  axios.get(`${API_ROOT}${ENV}/report/get-chart?id=${id}&type=${type}`, config)

export const getAggregatedReport = params =>
  axios.post(`${API_ROOT}${ENV}/report/get-aggregated-chart`, params, config)
