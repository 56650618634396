const PURPLE = [
  "#8F4DE2",
  "#A06CE3",
  "#AF84E4",
  "#B590E3",
  "#BC9CE3",
  "#C2A8E3",
  "#DFC9FA",
  "#F6EEFF",
]

export default PURPLE
