export default value => {
  value = value.replace(
    "SWANA (South West Asian/ North African), MENA (Middle Eastern/North African)",
    "SWANA/MENA"
  )

  switch (value) {
    case "he/him":
      return "He/Him"
    case "she/her":
      return "She/Her"
    case "they/them":
      return "They/Them"
    case "ze/zir":
      return "Ze/Zir"
    case "xe/xir":
      return "Xe/Xir"
    case "v-do":
      return "Military veteran"
    case "v-do not":
      return "Not a veteran"
    case "v-Prefer not to say":
      return "Prefer not to say"
    case "v-N/A":
      return "No data"
    case "d-do":
      return "Lives with a disability"
    case "d-do not":
      return "No disability"
    case "d-Prefer not to say":
      return "Prefer not to say"
    case "d-N/A":
      return "No data"
    case "N/A":
      return "No data"
    case "SWANA (South West Asian/ North African), MENA (Middle Eastern/North African)":
      return "SWANA/MENA"
    case "Learning / behavioral disability (i.e., dyslexia, ADHD)":
      return "Learning/Behavioral"
    case "Non-Binary/Gender Non-Conforming/Gender-Queer":
      return "Non-Binary"
    case "proactive":
      return "Proactive/On set"
    case "pre-enrollment":
      return "Pre-Enrollment"
    case "bidding":
      return "Bidding"
    case "retroactive":
      return "Retroactive"
    default:
      return value
  }
}
