import React, { useState } from "react"
import {
  Button,
  FormControl,
  Divider,
  Select,
  Popover,
  TextField,
  MenuItem,
  ListItemText,
  Checkbox,
} from "@material-ui/core"
import { PopoverWrapper, RoleDropdownWrapper } from "./style"
import { makeStyles } from "@material-ui/core/styles"
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline"

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 200,
    maxWidth: 250,
    marginLeft: 20,
  },
  popover: {
    pointerEvents: "none",
    maxWidth: 350,
  },
  select: {
    height: 45,
    background: "#fff",
    borderRadius: 4,
  },
}))

const RoleFilter = ({
  roleOption,
  roles,
  setRoles,
  roleNames,
  setRoleNames,
  setFetching,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const allRoles = [
    {
      id: "0",
      name: "All roles",
      count: 0,
      isCustom: 0,
      nameWithCount: "All roles",
    },
    ...roleOption,
  ]
  const [roleOptions, setRoleOptions] = useState(allRoles)
  const [selectedRoles, setSelectedRoles] = useState(
    roles.length === 0 ? ["0"] : roles
  )
  const [selectedRoleNames, setSelectedRoleNames] = useState(roleNames)
  const [isAllRoles, setIsAllRoles] = useState(
    selectedRoles.includes("0") ? true : false
  )
  const [filterOpen, setFilterOpen] = useState(false)
  const [ready, setReady] = useState(true)

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  const searchRoles = e => {
    const { value } = e.target
    if (value !== "") {
      setRoleOptions(
        allRoles.filter(role => role.name.toLowerCase().includes(value))
      )
    } else {
      setRoleOptions(allRoles)
    }
  }

  const onSubmit = () => {
    setFetching(true)
    if (selectedRoles.length === 0) {
      setRoles(["0"])
      setSelectedRoles(["0"])
      setSelectedRoleNames(["All roles"])
      setRoleNames(["All roles"])
    } else {
      setRoles(selectedRoles)
      setRoleNames(selectedRoleNames)
    }
    setRoleOptions(allRoles)
    setFilterOpen(false)
  }

  return (
    <div className="role-filter-wrapper">
      <h3>Filter By:</h3>

      <FormControl className={classes.formControl}>
        <label>Roles</label>
        {ready && (
          <Select
            multiple
            value={selectedRoles}
            variant="filled"
            className={classes.select}
            renderValue={selected => {
              return selected
                .map(sel => roleOptions?.find(role => role.id === sel)?.name)
                .join(", ")
            }}
            onClick={() => {
              !filterOpen && setFilterOpen(true)
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              getContentAnchorEl: null,
            }}
            onClose={onSubmit}
            open={filterOpen}
          >
            <RoleDropdownWrapper>
              <div className="dropdown-wrapper">
                <TextField
                  onKeyDown={e => e.stopPropagation()}
                  placeholder="Search roles"
                  variant="filled"
                  onChange={searchRoles}
                />
                <div className="item-container">
                  {roleOptions.length > 0 &&
                    roleOptions.map(i => (
                      <MenuItem
                        onKeyDown={e => e.stopPropagation()}
                        key={i.id}
                        value={i.id}
                        onClick={() => {
                          if (i.id === "0") {
                            setIsAllRoles(!isAllRoles)
                            isAllRoles
                              ? setSelectedRoles([])
                              : setSelectedRoles(["0"])
                            !selectedRoles.includes("0")
                              ? setSelectedRoleNames(["All roles"])
                              : setSelectedRoleNames([])
                          } else {
                            setIsAllRoles(false)
                            if (selectedRoles.includes("0")) {
                              setSelectedRoles(selectedRoles.shift())
                              setSelectedRoleNames(selectedRoleNames.shift())
                            }
                            if (selectedRoles.includes(i.id)) {
                              setSelectedRoles(
                                selectedRoles.filter(r => r !== i.id)
                              )
                              setSelectedRoleNames(
                                selectedRoleNames.filter(s => s !== i.name)
                              )
                            } else {
                              setSelectedRoles([...selectedRoles, i.id])
                              setSelectedRoleNames([
                                ...selectedRoleNames,
                                i.name,
                              ])
                            }
                          }
                        }}
                      >
                        <Checkbox
                          checked={
                            isAllRoles ||
                            selectedRoles.includes(i.id) ||
                            selectedRoles.includes("0")
                          }
                          iconstyle={{ fill: "black" }}
                          inputstyle={{ color: "black" }}
                          style={{ color: "black" }}
                        />
                        <ListItemText primary={i.nameWithCount} />
                      </MenuItem>
                    ))}
                </div>
                <Divider />
                <div className="dropdown-action">
                  <Button
                    onClick={() => {
                      setSelectedRoles([])
                      setSelectedRoleNames([])
                      setIsAllRoles(false)
                    }}
                  >
                    Clear All
                  </Button>
                  <Button
                    variant="contained"
                    className="submit"
                    onClick={onSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </RoleDropdownWrapper>
          </Select>
        )}

        <div
          className="tip"
          aria-owns={open ? "mouse-over-popover" : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <ErrorOutlineIcon />
          <p>TIP: Must have at least 6 responses to generate report</p>

          <Popover
            id="mouse-over-popover"
            className={classes.popover}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <PopoverWrapper>
              <p>
                To protect users' anonymity, INVOKE requires at least 6
                responses in order to generate metrics.
              </p>
              <p>
                NOTE: Because users may select multiple roles, role count may
                not equal response count
              </p>
            </PopoverWrapper>
          </Popover>
        </div>
      </FormControl>
    </div>
  )
}

export default RoleFilter
