import React from "react"
import { Grid } from "@material-ui/core"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Pie } from "react-chartjs-2"
import GREEN from "../../../constant/greenList"
import valueLookups from "../../../util/lookups"
ChartJS.register(ArcElement, Tooltip, Legend)

const VeteransDisabilityTab = ({ disabilityStatus, veteranStatus }) => {
  const perChunk = 8

  const disabilityResult = disabilityStatus.chart.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    },
    []
  )

  const veteranResult = veteranStatus.chart.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    },
    []
  )

  const veteranStatusData = {
    labels: veteranStatus.chart.map(v => valueLookups(`v-${v.name}`)),
    datasets: [
      {
        data: veteranStatus.chart.map(v => v.total),
        backgroundColor: GREEN,
      },
    ],
  }

  const disabilityStatusData = {
    labels: disabilityStatus.chart.map(v => valueLookups(`d-${v.name}`)),
    datasets: [
      {
        data: disabilityStatus.chart.map(v => v.total),
        backgroundColor: GREEN,
      },
    ],
  }

  const options = {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  }

  return (
    <div className="pronouns-container">
      <Grid container>
        <Grid item xs={12} md={6}>
          <h3>Veteran Status</h3>
          <div className="pie-chart-container">
            <Pie
              data={veteranStatusData}
              options={options}
              className="veteran-chart"
            />
          </div>
          <div className="legend-wrapper">
            <table>
              <tbody>
                {veteranResult.map(list =>
                  list.map((item, index) => (
                    <tr key={index}>
                      <td width={40}>
                        <i
                          style={{
                            background: GREEN[index],
                          }}
                        />
                      </td>
                      <td>{valueLookups(`v-${item.name}`)}</td>

                      <td>{item?.percentage?.toFixed(2)}%</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <h3>Disability Status</h3>
          <div className="pie-chart-container">
            <Pie
              data={disabilityStatusData}
              options={options}
              className="disablility-chart"
            />
          </div>
          <div className="legend-wrapper">
            <table>
              <tbody>
                {disabilityResult.map(list =>
                  list.map((item, index) => (
                    <tr key={index}>
                      <td width={40}>
                        <i
                          style={{
                            background: GREEN[index],
                          }}
                        />
                      </td>
                      <td>{valueLookups(`d-${item.name}`)}</td>

                      <td>{item?.percentage?.toFixed(2)}%</td>
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default VeteransDisabilityTab
