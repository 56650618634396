import React, { useState, useEffect } from "react"
import Header from "./Header"
import { makeStyles } from "@material-ui/core/styles"
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown"
import { ReportDashboardWrapper, ReportModal } from "./style"
import {
  Button,
  Chip,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core/"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import ModalArchive from "./ModalArchive"
import ModalEditTags from "./ModalEditTags"
import Loading from "../../shared/Loading"
import { getReportDashboard } from "../../httpRequests/report"
import moment from "moment"
import { Link, navigate } from "gatsby"
import Snackbar from "../../shared/Snackbar"
import { addExternalTags } from "../../httpRequests/report"
import jobNumber from "../../util/jobNumber"
import CustomReport from "./CustomReport"
import Footer from "./Footer"
import _ from "lodash"

const useStyles = makeStyles(theme => ({
  select: {
    maxHeight: 42,
    minWidth: 240,
    border: "1px solid #B3B3B3",
    borderRadius: 8,
    " &:before, &:after": {
      borderBottom: "none !important",
    },
  },
}))

function ReportDashboard() {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const [open, setOpen] = useState(false)
  const [modalType, setModalType] = useState("")
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [sortBy, setSortBy] = useState("updated_at")
  const [filterBy, setFilterBy] = useState("all")
  const [reportId, setReportId] = useState(null)
  const [reportType, setReportType] = useState(null)
  const [tags, setTags] = useState([])
  const [isWorkingWIthFtw, setIsWorkingWIthFtw] = useState(false)

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [success, setSuccess] = useState("")
  const [message, setMessage] = useState("")
  const [reload, setReload] = useState(false)

  const [reportTypeList, setReportTypeList] = useState([])
  const [tagsList, setTagsList] = useState([])
  const [refetch, setRefetch] = useState(false)

  const handleMenuClick = index => event => {
    setAnchorEl(event.currentTarget)
    setCurrentIndex(index)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  useEffect(async () => {
    setLoading(true)
    try {
      const response = await getReportDashboard(
        1,
        999,
        sortBy,
        sortBy === "project_name" ? "asc" : "desc",
        filterBy.toLocaleUpperCase()
      )

      setData([])
      if (response.data.statusCode === 200) {
        setLoading(false)
        setData(response.data.devMessage.data)
        setIsWorkingWIthFtw(response.data.devMessage.isWorkingWIthFtw)
      } else {
        setLoading(false)
        setSnackbarOpen(true)
        setSuccess("error")
        setMessage(response.data.devMessage)
        setReload(false)
      }
    } catch (error) {
      if (error.response.data.devMessage.includes("expired")) {
        navigate("/error/?token-expire")
      }
    }
  }, [sortBy, filterBy, refetch])

  useEffect(() => {
    const uniqueType = []
    const allTags = []

    data.map(i => {
      uniqueType.push({
        id:
          i.type === "PROACTIVE"
            ? 1
            : i.type === "PRE-ENROLLMENT"
            ? 2
            : i.type === "BIDDING"
            ? 3
            : 4,
        type: i.type.toLowerCase(),
      })

      i.externalTags.length > 0 &&
        allTags.push({
          type: i.type.toLowerCase(),
          tags: i.externalTags.map(v => v.toLowerCase()),
        })
    })
    setReportTypeList(_.sortBy(_.unionBy(uniqueType, "type"), ["id"]))
    setTagsList(allTags)
  }, [data, refetch])

  return (
    <ReportDashboardWrapper>
      <Header />
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="container">
            <div className="report-actions-wrapper">
              <h3>My Reports</h3>
              <div className="actions flex">
                <div className="flex">
                  <p>Sort by: </p>

                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    className={classes.select}
                    value={sortBy}
                    onChange={e => setSortBy(e.target.value)}
                  >
                    <MenuItem value={"updated_at"}>
                      Most recently updated
                    </MenuItem>
                    <MenuItem value={"created_at"}>
                      Most recently added
                    </MenuItem>
                    <MenuItem value={"project_name"}>
                      Project name (A-Z)
                    </MenuItem>
                  </Select>
                </div>
                <div className="types flex">
                  <p>Type:</p>

                  <Select
                    IconComponent={KeyboardArrowDownIcon}
                    className={classes.select}
                    value={filterBy}
                    onChange={e => setFilterBy(e.target.value)}
                  >
                    <MenuItem value={"all"}>All types</MenuItem>
                    <MenuItem value={"proactive"}>Proactive/On Set</MenuItem>
                    <MenuItem value={"pre-enrollment"}>Pre-Enrollment</MenuItem>
                    <MenuItem value={"bidding"}>Bidding</MenuItem>
                    <MenuItem value={"retroactive"}>Retroactive</MenuItem>
                    <MenuItem value={"archived"}>Archived</MenuItem>
                  </Select>
                </div>

                {isWorkingWIthFtw && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      setModalType("customReport")
                      setOpen(true)
                    }}
                  >
                    Create custom report
                  </Button>
                )}
              </div>
            </div>
            <div className="table-wrapper">
              <Table id="report-dashboard">
                <TableHead>
                  <TableRow className="header">
                    <TableCell>Project Name</TableCell>
                    <TableCell>Job Number</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Updated</TableCell>
                    <TableCell>Responses</TableCell>
                    <TableCell>Tags</TableCell>
                    <TableCell>Report</TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.length > 0 &&
                    data.map((report, index) => (
                      <TableRow key={report.id}>
                        <TableCell>
                          {report.reportStatus === 0 ? (
                            report.projectName
                          ) : (
                            <Link
                              to={`/report-dashboard/?id=${report.id}&type=${report.type}&print=false`}
                            >
                              {report.projectName}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>
                          {jobNumber(report.type, report.id)}
                        </TableCell>
                        <TableCell>
                          {report.type === "PROACTIVE"
                            ? "Proactive/On Set"
                            : report.type === "PRE-ENROLLMENT"
                            ? "Pre-Enrollment"
                            : report.type === "BIDDING"
                            ? "Bidding"
                            : report.type === "RETROACTIVE"
                            ? "Retroactive"
                            : ""}
                        </TableCell>

                        <TableCell>
                          {report.updatedAt === null
                            ? "N/A"
                            : moment(report.updatedAt).format("MMM DD, YYYY")}
                        </TableCell>
                        <TableCell align="center">{report.responses}</TableCell>
                        <TableCell className="tag-column">
                          {report?.externalTags.length === 0 && index === 0 ? (
                            <span className="ghost-text">
                              e.g. Quarter, Department
                            </span>
                          ) : (
                            report?.externalTags?.map((tag, index) => (
                              <Chip
                                key={index}
                                size="small"
                                label={tag}
                                onDelete={async () => {
                                  setLoading(true)

                                  try {
                                    const response = await addExternalTags({
                                      id: parseInt(report.id),
                                      type: report.type,
                                      tags: report.externalTags.filter(
                                        t => t !== tag
                                      ),
                                    })

                                    if (response.data.statusCode === 200) {
                                      setSnackbarOpen(true)
                                      setSuccess("success")
                                      setMessage(
                                        "Tag(s)'s been updated successfully!"
                                      )
                                      setRefetch(!refetch)
                                    } else {
                                      setSnackbarOpen(false)
                                      setSuccess("error")
                                      setMessage("Oops! Please try again.")
                                      setReload(false)
                                    }
                                  } catch (error) {
                                    if (
                                      error.response.data.devMessage.includes(
                                        "Method Not"
                                      )
                                    ) {
                                      setSnackbarOpen(true)
                                      setSuccess("error")
                                      setMessage(
                                        "You don't have a permission to perform this action."
                                      )
                                      setReload(false)
                                    }
                                  }

                                  setLoading(false)
                                }}
                              />
                            ))
                          )}
                        </TableCell>
                        <TableCell>
                          {report.reportStatus === 0 ? (
                            "Pending"
                          ) : (
                            <Link
                              to={`/report-dashboard/?id=${report.id}&type=${report.type}&print=false`}
                            >
                              View report
                            </Link>
                          )}
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={handleMenuClick(index)}>
                            <MoreVertIcon />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl) && index === currentIndex}
                            onClose={handleMenuClose}
                          >
                            <MenuItem
                              onClick={() => {
                                setOpen(true)
                                setModalType("editTags")
                                setReportId(report.id)
                                setReportType(report.type)
                                setTags(report.externalTags)
                                handleMenuClose()
                              }}
                            >
                              Edit Tags
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setOpen(true)
                                setModalType("archive")
                                setReportId(report.id)
                                setReportType(report.type)
                                handleMenuClose()
                              }}
                              disabled={filterBy === "archived"}
                            >
                              Archive Report
                            </MenuItem>
                          </Menu>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </div>
          </div>
          <Footer />
        </>
      )}

      <Dialog open={open} maxWidth="md" onClose={() => setOpen(false)}>
        <ReportModal>
          <DialogContent>
            {modalType === "archive" && (
              <ModalArchive
                setOpen={setOpen}
                setLoading={setLoading}
                reportId={reportId}
                reportType={reportType}
                setSnackbarOpen={setSnackbarOpen}
                setSuccess={setSuccess}
                setMessage={setMessage}
              />
            )}
            {modalType === "editTags" && (
              <ModalEditTags
                setOpen={setOpen}
                tags={tags}
                setLoading={setLoading}
                reportId={reportId}
                reportType={reportType}
                setSnackbarOpen={setSnackbarOpen}
                setSuccess={setSuccess}
                setMessage={setMessage}
                setErrorOpen={setSnackbarOpen}
                setErrorMessage={setMessage}
                setRefetch={setRefetch}
                refetch={refetch}
              />
            )}

            {modalType === "customReport" && (
              <CustomReport
                setOpen={setOpen}
                reportTypeList={reportTypeList}
                tagsList={tagsList}
              />
            )}
          </DialogContent>
        </ReportModal>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        success={success}
        message={message}
        reload={reload}
        setSnackbarOpen={setSnackbarOpen}
      />
    </ReportDashboardWrapper>
  )
}

export default ReportDashboard
