import React from "react"
import { Snackbar as MaterialSnackbar } from "@material-ui/core"
import MuiAlert from "@material-ui/lab/Alert"

const Snackbar = ({ open, success, message, reload, setSnackbarOpen }) => {
  const onClickCloseSnackbar = () => {
    if (reload) {
      window.location.reload()
    } else {
      setSnackbarOpen(false)
    }
  }

  return (
    <MaterialSnackbar
      open={open}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      autoHideDuration={3000}
      onClose={onClickCloseSnackbar}
      disableWindowBlurListener
    >
      <MuiAlert
        elevation={6}
        variant="filled"
        onClose={onClickCloseSnackbar}
        severity={success}
      >
        {message}
      </MuiAlert>
    </MaterialSnackbar>
  )
}

export default Snackbar
