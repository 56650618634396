import React, { useEffect, useState } from "react"
import Header from "./Header"
import {
  PrintableWrapper,
  ReportDashboardWrapper,
  ReportDetailWrapper,
  ReportModal,
} from "./style"
import { getChart, getAggregatedReport } from "../../httpRequests/report"
import Loading from "../../shared/Loading"
import { Button, Dialog, DialogContent, Grid } from "@material-ui/core"
import ProductionDetail from "./Details/productionDetails"
import Summary from "./Details/summary"
import LanguageAncestryTab from "./Details/languages"
import PronounsGenderTab from "./Details/pronouns"
import AgeTab from "./Details/age"
import VeteransDisabilityTab from "./Details/veterans"
import { navigate } from "gatsby"
import { generatePDF } from "../../util/generatePDFReport"
import jobNumber from "../../util/jobNumber"
import formatCSV from "../../util/formatCSV"
import createCSVFile from "../../util/createCSVFile"
import { uniq } from "lodash"
import InsufficientData from "./InsufficientData"
import Footer from "./Footer"
import RoleFilter from "./RoleFilter"
import FeedbackTab from "./Details/feedback"
import AnalysisTab from "./Details/analysis"
import formatCustomQuestionCSV from "../../util/formatCustomQuestionCSV"

function ReportDetail(props) {
  const { id, print, type, aggregated } = props
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)
  const [tabData, setTabData] = useState(1)
  const [pieChartCounter, setPieChartCounter] = useState({
    ethnicity: 1,
    multiRacial: 1,
    asian: 1,
    veteran: 1,
    disability: 1,
    hispanic: 1
  })
  const [open, setOpen] = useState(false)
  const [errorOpen, setErrorOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [noAggregated, setNoAggregated] = useState(false)
  const [roles, setRoles] = useState([])
  const [roleNames, setRoleNames] = useState(["All roles"])
  const [fetching, setFetching] = useState(false)
  const [notEnough, setNotEnough] = useState(false)
  const [allRoles, setAllRoles] = useState([])
  const [noDataCrew, setNoDataCrew] = useState(0)
  const [isAggregated, setIsAggregated] = useState(false)
  const [refetch, setRefetch] = useState(false)

  useEffect(async () => {
    let response
    try {
      if (aggregated) {
        const tags = localStorage.getItem("aggregatedTags").split(",")
        response = await getAggregatedReport({
          type: type.toUpperCase(),
          tags: tags[0] !== "" ? tags : [],
          roles: roles[0] === "0" ? [] : roles,
        })
        if (roles.length > 0) {
          setIsAggregated(true)
        } else {
          setIsAggregated(false)
        }
      } else {
        response = await getChart(id, type)
      }

      if (response.data.statusCode === 200) {
        if (response.data.data === null) {
          setNoAggregated(true)
          setNotEnough(true)
          setNoDataCrew(response.data.responses)
        } else {
          setData(response.data.devMessage)
          setNoDataCrew(0)
          if (aggregated && roles.length === 0) {
            setAllRoles(response.data.devMessage.roles)
          }
          setNotEnough(false)
        }
        setLoading(false)
      }

      if (
        response.data.statusCode === 500 &&
        response.data.devMessage.includes("no access")
      ) {
        navigate("/error?no-access")
      }
    } catch (error) {
      if (error.response.data.devMessage.includes("expired")) {
        navigate("/error?token-expire")
      }
    }
    setFetching(false)
  }, [roles, fetching, refetch])

  const exportCSV = () => {
    const CSVData = formatCSV(data)
    createCSVFile(
      CSVData,
      aggregated ? "Aggregated Report" : data?.projectInfo?.projectName
    )
  }

  const exportCustomQuestion = () => {
    const csv = formatCustomQuestionCSV(data)
    createCSVFile(csv, "Custom Question Report")
  }

  return (
    <ReportDashboardWrapper>
      <Header aggregated={aggregated} />
      {loading ? (
        <Loading />
      ) : (
        <ReportDetailWrapper>
          {!isAggregated && (data.data === null || noAggregated) ? (
            <div className="detail-header not-enough-data">
              <div className="container">
                <InsufficientData />
              </div>
            </div>
          ) : print === "true" ? (
            <div id="print">
              <PrintableWrapper>
                <Loading />
                <div className="hide" />
                <Summary
                  participationRate={data.participationRate}
                  underRepresented={data.underRepresented}
                  engagement={data.engagement}
                  type={type}
                  roleNames={roleNames}
                  print
                />
                <LanguageAncestryTab
                  language={data.language}
                  ancestry={data.ancestry}
                  ethnicity={data.ethnicity}
                  asian={data.asian}
                  multiRacial={data.multiRacial}
                  aggregated={aggregated}
                  hispanic={data.hispanic}
                  print
                />
                <PronounsGenderTab
                  pronoun={data.pronoun}
                  gender={data.gender}
                  sexualOrientation={data.sexualOrientation}
                  aggregated={aggregated}
                  print
                />
                <AgeTab age={data.age} print />
                <VeteransDisabilityTab
                  veteranStatus={data.veteranStatus}
                  disabilityStatus={data.disabilityStatus}
                />
              </PrintableWrapper>
            </div>
          ) : (
            <div>
              <div
                className={
                  aggregated
                    ? "detail-header aggregated-header"
                    : "detail-header"
                }
              >
                <div className="container">
                  <Grid container>
                    <Grid item xs={12} md={6}>
                      {!aggregated ? (
                        <>
                          <h1>{data?.projectInfo?.projectName || ""}</h1>
                          {data?.projectInfo?.companyName && (
                            <p>
                              This report commission by:{" "}
                              <span>
                                {data?.projectInfo?.companyName || ""}
                              </span>
                            </p>
                          )}
                          <p>
                            <span>Job #: {jobNumber(type, id)}</span>
                          </p>
                        </>
                      ) : (
                        <RoleFilter
                          roles={roles}
                          setRoles={setRoles}
                          roleNames={roleNames}
                          setRoleNames={setRoleNames}
                          setFetching={setFetching}
                          roleOption={allRoles}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div className="actions">
                        {!aggregated && (
                          <Button
                            className="viewer-list"
                            variant="contained"
                            onClick={() => {
                              setOpen(true)
                            }}
                          >
                            Viewer List
                          </Button>
                        )}

                        {!notEnough && (
                          <Button
                            className="confirm-action"
                            variant="contained"
                            onClick={() =>
                              generatePDF(
                                data,
                                id,
                                type,
                                pieChartCounter,
                                setPieChartCounter,
                                aggregated,
                                roleNames
                              )
                            }
                          >
                            Export PDF
                          </Button>
                        )}

                        {!aggregated && (
                          <Button
                            variant="contained"
                            className="export-csv"
                            onClick={exportCSV}
                          >
                            Export CSV
                          </Button>
                        )}
                      </div>
                      {data?.customQuestions?.length > 0 && (
                        <div className="custom-question">
                          <p>
                            *This project had a custom question. Survey answers
                            to the custom can be found{" "}
                            <a href="#" onClick={exportCustomQuestion}>
                              here
                            </a>
                            .
                          </p>
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </div>
              </div>

              {fetching ? (
                <Loading />
              ) : (
                <>
                  <div className="report-detail-container">
                    <div className="container">
                      <Grid container>
                        <Grid item xs={12} md={6}>
                          <ProductionDetail
                            projectInfo={data?.projectInfo}
                            totalCrew={data.totalCrew}
                            id={id}
                            type={type}
                            setLoading={setLoading}
                            setErrorOpen={setErrorOpen}
                            errorOpen={errorOpen}
                            setErrorMessage={setErrorMessage}
                            errorMessage={errorMessage}
                            aggregated={aggregated}
                            externalTags={
                              data?.externalTags?.length > 0
                                ? uniq(
                                    data.externalTags.map(i => i.toLowerCase())
                                  )
                                : []
                            }
                            projectsNames={data.projectsNames || []}
                            roleNames={roleNames}
                            noDataCrew={noDataCrew}
                            customReportNote={
                              data?.projectInfo?.customReportNote
                            }
                            refetch={refetch}
                            setRefetch={setRefetch}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Summary
                            participationRate={data.participationRate}
                            underRepresented={data.underRepresented}
                            engagement={data.engagement}
                            aggregated={aggregated}
                            engagementParticipationRate={
                              data.engagementParticipationRate
                            }
                            type={type}
                            roleNames={roleNames}
                            notEnough={notEnough}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>

                  {!notEnough && (
                    <div className="container no-scroll">
                      <div className="tab-container flex">
                        <Button
                          className={tabData === 1 ? "active" : "tab"}
                          onClick={() => setTabData(1)}
                        >
                          Language, Ancestry & Ethnicity
                        </Button>
                        <Button
                          className={tabData === 2 ? "active" : "tab"}
                          onClick={() => setTabData(2)}
                        >
                          Gender & Orientation
                        </Button>
                        <Button
                          className={tabData === 3 ? "active age" : "tab age"}
                          onClick={() => setTabData(3)}
                        >
                          Age
                        </Button>
                        <Button
                          className={tabData === 4 ? "active" : "tab"}
                          onClick={() => setTabData(4)}
                        >
                          Veterans & Disablility Status
                        </Button>
                        {data?.selectedFeedback?.length > 0 && (
                          <Button
                            className={tabData === 5 ? "active" : "tab"}
                            onClick={() => setTabData(5)}
                          >
                            Crew Feedback
                          </Button>
                        )}
                        {data?.feedbackAnalysis?.length > 0 && (
                          <Button
                            className={tabData === 6 ? "active" : "tab"}
                            onClick={() => setTabData(6)}
                          >
                            FTW Analysis
                          </Button>
                        )}
                      </div>

                      {tabData === 1 && (
                        <LanguageAncestryTab
                          language={data.language}
                          ancestry={data.ancestry}
                          ethnicity={data.ethnicity}
                          asian={data.asian}
                          multiRacial={data.multiRacial}
                          aggregated={aggregated}
                          hispanic={data.hispanic}
                        />
                      )}

                      {tabData === 2 && (
                        <PronounsGenderTab
                          gender={data.gender}
                          sexualOrientation={data.sexualOrientation}
                        />
                      )}

                      {tabData === 3 && <AgeTab age={data.age} />}

                      {tabData === 4 && (
                        <VeteransDisabilityTab
                          veteranStatus={data.veteranStatus}
                          disabilityStatus={data.disabilityStatus}
                        />
                      )}

                      {tabData === 5 && (
                        <FeedbackTab feedback={data.selectedFeedback || []} />
                      )}

                      {tabData === 6 && (
                        <AnalysisTab data={data.feedbackAnalysis || []} />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
          <Footer />
        </ReportDetailWrapper>
      )}

      <Dialog open={open} maxWidth="md" onClose={() => setOpen(false)}>
        <ReportModal>
          <DialogContent>
            <div className="edit-tags viewer-list">
              <h3>Shared with</h3>
              <div className="viewer-wrapper">
                {data?.attachedExternalUser?.length > 0 &&
                  data?.attachedExternalUser.map((user, index) => (
                    <div className="viewer-item" key={index}>
                      <div>
                        <p>
                          {user.firstName} {user.lastName}
                        </p>
                        <p>
                          <i>{user.email}</i>
                        </p>
                      </div>
                      <p>Viewer</p>
                    </div>
                  ))}
              </div>
              <p className="note">
                Contact your administrator to update sharing settings!
              </p>
              <div className="modal-actions">
                <Button
                  className="confirm"
                  variant="filled"
                  onClick={() => setOpen(false)}
                >
                  Close
                </Button>
              </div>
            </div>
          </DialogContent>
        </ReportModal>
      </Dialog>
    </ReportDashboardWrapper>
  )
}

export default ReportDetail
