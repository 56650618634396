import React, { useEffect } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ReportDashboard from "../components/ReportDashboard"
import ReportDetail from "../components/ReportDashboard/ReportDetail"
import { navigate } from "gatsby"
import queryString from "query-string"
const ReportDashboardPage = props => {
  const { search } = props.location
  const { id, type, print, aggregated } = queryString.parse(search)

  const validToken =
    typeof window !== "undefined" &&
    localStorage.getItem("insightToken") !== null &&
    localStorage.getItem("insightToken") !== "undefined"
  useEffect(() => {
    if (!validToken) navigate("/no-access")
  }, [])

  return (
    <Layout login>
      <Seo title="Report Dashboard - INVOKE by FREE THE WORK" />
      {validToken && (
        <>
          {search === "" && <ReportDashboard />}
          {search !== "" && (
            <ReportDetail
              id={id}
              type={type}
              print={print}
              aggregated={aggregated}
              {...props}
            />
          )}
        </>
      )}
    </Layout>
  )
}

export default ReportDashboardPage
