import React from "react"
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js"
import { Pie } from "react-chartjs-2"
import { Button } from "@material-ui/core"
import { navigate } from "gatsby"
ChartJS.register(ArcElement, Tooltip, Legend)

const Summary = ({
  engagement,
  participationRate,
  underRepresented,
  engagementParticipationRate,
  aggregated,
  type,
  roleNames,
  notEnough,
}) => {
  const data = {
    labels: ["Underrepresented", "Not underrepresented", "No Data"],
    datasets: [
      {
        data: [
          underRepresented?.underCount,
          underRepresented?.notUnderCount,
          underRepresented?.noData.count,
        ],
        backgroundColor: ["#74D6BF", "#A5E4D4", "#CAF3E9"],
      },
    ],
  }

  const options = {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  }

  return (
    <div className="summary">
      <h3>Summary</h3>

      {notEnough ? (
        <div className="not-enough-data">
          <p>Uh oh!</p>
          <p>
            It looks like we don't have enough data to generate this report.
          </p>

          <p>
            INVOKE requires at least 6 responses in order to protect creators'
            anonymity.
          </p>

          <p>
            You can add more roles to this report using the filter above or
            click below to return to your dashboard.
          </p>

          <p>And in the meantime, let's get on more sets!</p>

          <p className="center">
            <Button
              variant="contained"
              onClick={() => {
                navigate("/report-dashboard")
              }}
            >
              Back to Dashboard
            </Button>
          </p>
        </div>
      ) : (
        <>
          <div className="top">
            {roleNames[0] === "All roles" && (
              <p>
                This {aggregated ? "sample set" : "project"} had a{" "}
                <span>{participationRate}%</span> overall participation rate.
              </p>
            )}

            {roleNames[0] === "All roles" &&
              (type === "PROACTIVE" || type === "proactive") && (
                <p>
                  The INVOKE team spoke with <span>{engagement}%</span> of all
                  crew. Of those, <span>{engagementParticipationRate}%</span>{" "}
                  took the survey.
                </p>
              )}
          </div>
          <div className="pie-chart-container">
            <Pie data={data} options={options} className="summary-chart" />
          </div>

          <div className="top">
            <p>
              At least{" "}
              <span>{underRepresented.underPercentage}% of creators</span> on
              this project identified with at least 1 underrepresented
              background.
            </p>
          </div>
          <div className="note">
            <p>
              Crew members who declined to participate are accounted for as "No
              data"
            </p>
          </div>
          <div className="legend-wrapper summary">
            <table>
              <tbody>
                <tr>
                  <td width={40}>
                    <i style={{ background: "#74D6BF" }} />
                  </td>
                  <td>Underrepresented</td>
                  <td>{underRepresented?.underPercentage?.toFixed(2)}%</td>
                </tr>
                <tr>
                  <td width={40}>
                    <i style={{ background: "#A5E4D4" }} />
                  </td>
                  <td>Not underrepresented</td>
                  <td>{underRepresented?.notUnderPercentage?.toFixed(2)}%</td>
                </tr>
                <tr>
                  <td width={40}>
                    <i style={{ background: "#CAF3E9" }} />
                  </td>
                  <td>No data</td>
                  <td>{underRepresented?.noData?.percentage?.toFixed(2)}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  )
}

export default Summary
