import React from "react"
import { Grid } from "@material-ui/core"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar, Pie } from "react-chartjs-2"
import PURPLE from "../../../constant/purpleList"
import GREEN from "../../../constant/greenList"
import ChartDataLabels from "chartjs-plugin-datalabels"
import valueLookups from "../../../util/lookups"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
)
const LanguageAncestryTab = ({
  language,
  ancestry,
  ethnicity,
  asian,
  multiRacial,
  print,
  aggregated,
  hispanic
}) => {
  const langChartHeight = print
    ? language.chart.slice(0, 20).length * 30
    : language.chart.length * 46
  const ancestryHeight = print
    ? ancestry.chart.slice(0, 20).length * 30
    : ancestry.chart.length * 46

  const multiHeight = print
    ? multiRacial.chart.length * 30
    : multiRacial.chart.length * 46
  const perChunk = 8

  const ethnicityResult = ethnicity.chart.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  const asianResult = asian.chart.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / perChunk)

    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []
    }

    resultArray[chunkIndex].push(item)

    return resultArray
  }, [])

  const multiRacialResult = multiRacial.chart.reduce(
    (resultArray, item, index) => {
      const chunkIndex = Math.floor(index / perChunk)

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []
      }

      resultArray[chunkIndex].push(item)

      return resultArray
    },
    []
  )

  const languageData = {
    labels: print
      ? language.chart.slice(0, 20).map(item => item.name)
      : language.chart.map(item => item.name),
    datasets: [
      {
        data: print
          ? language.chart.slice(0, 20).map(item => item.total)
          : language.chart.map(item => item.total),
        backgroundColor: PURPLE,
        barThickness: print ? 30 : 46,
        count: print
          ? language.chart.slice(0, 20).map(item => item.total)
          : language.chart.map(item => item.total),
      },
    ],
  }

  const ancestryData = {
    labels: print
      ? ancestry.chart.slice(0, 20).map(item => item.name)
      : ancestry.chart.map(item => item.name),
    datasets: [
      {
        data: print
          ? ancestry.chart.slice(0, 20).map(item => item.total)
          : ancestry.chart.map(item => item.total),
        backgroundColor: PURPLE,
        barThickness: print ? 30 : 46,
        count: print
          ? ancestry.chart.slice(0, 20).map(item => item.total)
          : ancestry.chart.map(item => item.total),
      },
    ],
  }

  const ethnicityData = {
    labels: ethnicity.chart.map(item => valueLookups(item.name)),
    datasets: [
      {
        data: ethnicity.chart.map(item => item.total),
        backgroundColor: GREEN,
      },
    ],
  }

  const multiRacialData = {
    labels: multiRacial.chart.map(item => valueLookups(item.name)),
    datasets: [
      {
        data: multiRacial.chart.map(item => item.total),
        backgroundColor: PURPLE,
        barThickness: print ? 30 : 46,
        percentage: multiRacial.chart.map(item => item.percentage),
        datalabels: {
          labels: {
            name: {
              anchor: "end",
              align: "end",
              color: "#000",
              font: {
                size: 12,
                family: "LatoBold",
              },
              formatter: function (_, ctx) {
                return `${valueLookups(
                  ctx.chart.data.labels[ctx.dataIndex]
                )} (${ctx.dataset?.percentage[ctx.dataIndex]?.toFixed(2)}%)`
              },
            },
            value: {
              align: "end",
              anchor: "end",
              color: "#000",
              display: false,
              font: {
                size: 12,
                family: "LatoBold",
              },
              formatter: function (_, ctx) {
                return `${ctx.dataset?.percentage[ctx.dataIndex]?.toFixed(2)}%`
              },
            },
          },
        },
      },
    ],
  }

  const asianData = {
    labels: asian.chart.map(item => valueLookups(item.name)),
    datasets: [
      {
        data: asian.chart.map(item => item.total),
        backgroundColor: GREEN,
      },
    ],
  }
  const hispanicData = {
    labels: hispanic.chart.map(item => valueLookups(item.name)),
    datasets: [
      {
        data: hispanic.chart.map(item => item.total),
        backgroundColor: GREEN,
      },
    ],
  }
  const pieChartOptions = {
    plugins: {
      datalabels: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  }

  const options = {
    indexAxis: "y",
    showTooltips: false,
    scales: {
      y: {
        display: false,
      },
      x: {
        display: false,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 150,
      },
    },
    plugins: {
      datalabels: {
        labels: {
          name: {
            anchor: "end",
            align: "end",
            color: "#000",
            font: {
              size: 12,
              family: "LatoBold",
            },
            formatter: function (_, ctx) {
              if (print) {
                return `${valueLookups(
                  ctx.chart.data.labels[ctx.dataIndex]
                )} (${ctx.dataset?.count[ctx.dataIndex]})`
              } else {
                return `${valueLookups(ctx.chart.data.labels[ctx.dataIndex])}`
              }
            },
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
  }



  return (
    <div className="language-container">
      <Grid container className="racials-container">
        <Grid item xs={12} md={6}>
          <h3>Ethnicity</h3>
          <div className="top">
            {ethnicity?.count > 0 ? (
              <p>
                Participants indentified across{" "}
                <span>{ethnicity?.count} different ethnic backgrounds</span>
              </p>
            ) : (
              <p>This information is not available through this survey</p>
            )}
          </div>
          <div className="pie-chart-container">
            <Pie
              data={ethnicityData}
              options={pieChartOptions}
              className="ethnicity-chart"
            />
          </div>
          <p className="remark">
            * Participants who select multiple ethnicities are noted as
            "multi-racial"
          </p>
        </Grid>
        {ethnicity?.count > 0 && (
          <Grid item xs={12} md={6} className="ethnicity-legend-container">
            <div className="legend-wrapper">
              <table>
                <tbody>
                  {ethnicityResult.map(list =>
                    list.map((item, index) => (
                      <tr key={index}>
                        <td width={40}>
                          <i
                            style={{
                              background: GREEN[index],
                            }}
                          />
                        </td>
                        <td>{valueLookups(item.name)}</td>

                        <td>{item?.percentage?.toFixed(2)}%</td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </Grid>
        )}
      </Grid>

      <div className="racials-container">
        {(multiRacial.chart.length > 0 || asian.chart.length > 0) && (
          <h2>Additional Ethnicity Breakdown</h2>
        )}

        <Grid container>
          {multiRacial.chart.length > 0 && (
            <Grid item xs={12} md={6}>
              <h3>Multi-racial</h3>
              <div className="bar-chart-container">
                <Bar
                  data={multiRacialData}
                  options={options}
                  height={multiHeight}
                  className="multi-racial-chart"
                />

                {/* <Pie
                  data={multiRacialData}
                  options={pieChartOptions}
                  className="multi-racial-chart"
                /> */}
              </div>
              <p className="remark note">
                Note: Participants may make multiple selections, so totals may
                exceed 100%
              </p>
            </Grid>
          )}

          {asian.chart.length > 0 && (
            <Grid item xs={12} md={6}>
              <h3>Asian</h3>
              <div className="pie-chart-container">
                <Pie
                  data={asianData}
                  options={pieChartOptions}
                  className="asian-chart"
                />
              </div>
              <div className="legend-wrapper add-top">
                <table>
                  <tbody>
                    {asianResult.map(list =>
                      list.map((item, index) => (
                        <tr key={index}>
                          <td width={40}>
                            <i
                              style={{
                                background: GREEN[index],
                              }}
                            />
                          </td>
                          <td>{valueLookups(item.name)}</td>

                          <td>{item?.percentage?.toFixed(2)}%</td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </Grid>
          )}

          {hispanic.chart.length > 0 && (
            <Grid item xs={12} md={6}>
              <h4>Hispanic/Latinx</h4>
              <div className="pie-chart-container">
                <Pie
                  data={hispanicData}
                  options={pieChartOptions}
                  className="hispanic-chart"
                />
              </div>
              <div className="legend-wrapper">
                <table>
                  <tbody>
                    {hispanic.chart.map((item, index) => {
                      if (item.total > 0) {
                        return (
                          <tr key={index}>
                            <td width={40}>
                              <i
                                style={{
                                  background: GREEN[index],
                                }}
                              />
                            </td>
                            <td>{valueLookups(item.name)}</td>

                            <td>{item?.percentage?.toFixed(2)}%</td>
                          </tr>
                        )
                      }
                    })}
                  </tbody>
                </table>
                <section className="note">
                  <p>
                    Note: Hispanic/Latinx is a discontinued option for the
                    future surveys.
                  </p>
                </section>
              </div>
            </Grid>
          )}
        </Grid>
      </div>

      <Grid container className="language-section">
        <Grid item xs={12} md={6}>
          <h3>Languages</h3>
          <div className="top">
            {language?.count > 0 ? (
              <p>
                There were <span>{language?.count} different languages</span>{" "}
                represented on {aggregated ? "these projects" : "this project"}
              </p>
            ) : (
              <p>This information is not available through this survey</p>
            )}
          </div>
          <div className="bar-chart-container">
            <Bar
              options={options}
              data={languageData}
              height={langChartHeight}
              className="lang-chart"
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <h3>Ancestries</h3>
          <div className="top">
            {ancestry?.count > 0 ? (
              <p>
                There were{" "}
                <span>{ancestry?.count} different ancestral backgrounds</span>{" "}
                represented on {aggregated ? "these projects" : "this project"}
              </p>
            ) : (
              <p>This information is not available through this survey</p>
            )}
          </div>
          <div className="bar-chart-container">
            <Bar
              options={options}
              data={ancestryData}
              height={ancestryHeight}
              className="ancestry-chart"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default LanguageAncestryTab
