import React from "react"
import { Grid } from "@material-ui/core"
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js"
import { Bar } from "react-chartjs-2"
import PURPLE from "../../../constant/purpleList"
import valueLookups from '../../../util/lookups'
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

const AgeTab = ({ age, print }) => {
  const ageChartHeight = print ? age.chart.length * 30 : age.chart.length * 46
  const ageData = {
    labels: age.chart.map(item => valueLookups(item.name)),
    datasets: [
      {
        data: age.chart.map(item => item.count),
        backgroundColor: PURPLE,
        barThickness: print ? 27 : 43,
      },
    ],
  }

  const options = {
    indexAxis: "y",
    showTooltips: false,
    scales: {
      y: {
        ticks: {
          color: "#252733",
          font: {
            size: print ? 11 : 14,
            family: "LatoBold",
          },
        },
        grid: {
          display: false,
        },
      },
      x: {
        display: false,
      },
    },
    responseive: false,
    maintainAspectRatio: false,
    layout: {
      padding: {
        right: 20,
      },
    },
    plugins: {
      datalabels: {
        labels: {
          name: {
            anchor: "end",
            align: "right",
            color: "#000",
            font: {
              size: print ? 11 : 13,
              family: "LatoBold",
            },
            formatter: function (value) {
              if (value > 0) {
                return `${value}`
              } else {
                return ""
              }
            },
          },
        },
      },
      tooltip: {
        enabled: true,
      },
      legend: {
        display: false,
      },
    },
  }

  return (
    <div className="age-container">
      <Grid container>
        <Grid item xs={12} md={6}>
          <h3>Age</h3>
          <div className="bar-chart-container age">
            <Bar
              options={options}
              data={ageData}
              height={ageChartHeight}
              className="age-chart"
            />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default AgeTab
